import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../../clay/version";

//!Data
export type MasterFormatCode = {
    id: UUID;
    recordVersion: Version;
    code: string;
    description: string;
};

// BEGIN MAGIC -- DO NOT EDIT
export type MasterFormatCodeJSON = {
    id: string;
    recordVersion: number | null;
    code: string;
    description: string;
};

export function JSONToMasterFormatCode(
    json: MasterFormatCodeJSON
): MasterFormatCode {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        code: json.code,
        description: json.description,
    };
}
export type MasterFormatCodeBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    code?: string;
    description?: string;
};

export function newMasterFormatCode(): MasterFormatCode {
    return JSONToMasterFormatCode(repairMasterFormatCodeJSON(undefined));
}
export function repairMasterFormatCodeJSON(
    json: MasterFormatCodeBrokenJSON | undefined
): MasterFormatCodeJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            code: json.code || "",
            description: json.description || "",
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            code: undefined || "",
            description: undefined || "",
        };
    }
}

export function MasterFormatCodeToJSON(
    value: MasterFormatCode
): MasterFormatCodeJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        code: value.code,
        description: value.description,
    };
}

export const MASTER_FORMAT_CODE_META: RecordMeta<
    MasterFormatCode,
    MasterFormatCodeJSON,
    MasterFormatCodeBrokenJSON
> & { name: "MasterFormatCode" } = {
    name: "MasterFormatCode",
    type: "record",
    repair: repairMasterFormatCodeJSON,
    toJSON: MasterFormatCodeToJSON,
    fromJSON: JSONToMasterFormatCode,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        code: { type: "string" },
        description: { type: "string" },
    },
    userFacingKey: null,
    functions: {},
    segments: {},
};

// END MAGIC -- DO NOT EDIT
