import { AdminTablePage } from "../../../clay/admin-table";
import SpectrumInvoiceTypeWidget from "./SpectrumInvoiceTypeWidget.widget";

export const SpectrumInvoiceTypesPage = AdminTablePage({
    title: "Cost Types",
    rowWidget: SpectrumInvoiceTypeWidget,
    columns: [
        { id: "code", label: "Code" },
        {
            id: "description",
            label: "Description",
        },
        {
            id: "active",
            label: "Active",
        },
    ],
    adminCategory: "projects",
    compare(lhs, rhs) {
        return lhs.code.localeCompare(rhs.code);
    },
});
