import Decimal from "decimal.js";
import React from "react";
import { Dictionary } from "../../clay/common";
import { propCheck } from "../../clay/propCheck";
import { QuickCacheApi } from "../../clay/quick-cache";
import { DropdownLinkWidget } from "../../clay/widgets/dropdown-link-widget";
import { Optional } from "../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    useRecordContext,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../clay/widgets/index";
import { MoneyStatic, MoneyWidget } from "../../clay/widgets/money-widget";
import { QuantityWidget } from "../../clay/widgets/number-widget";
import { SelectLinkWidget } from "../../clay/widgets/SelectLinkWidget";
import { SwitchWidget } from "../../clay/widgets/SwitchWidget";
import { TableRow } from "../../clay/widgets/TableRow";
import { TextAreaWidget } from "../../clay/widgets/TextAreaWidget";
import { UNIT_TYPE_META } from "../estimate/types/table";
import { DETAIL_SHEET_META } from "../project/detail-sheet/table";
import { MasterFormatLinkWidget } from "../project/master-format-codes/link";
import ProjectDescriptionDetailWidget from "../project/projectDescriptionDetail/ProjectDescriptionDetailWidget.widget";
import { PROJECT_SCHEDULE_META } from "../project/schedule";
import {
    calcContingencyItemTotal,
    computeContingencyItemRemdalCost,
    ContingencyItem,
    CONTINGENCY_ALLOWANCE_CONTINGENCY_TYPE,
    CONTINGENCY_ITEM_META,
    isLumpSumUnitType,
} from "./table";

export type Data = ContingencyItem;

const Fields = {
    description: TextAreaWidget,
    masterFormatCode: MasterFormatLinkWidget,
    type: DropdownLinkWidget({
        meta: UNIT_TYPE_META,
        label: (item) => item.name,
        include: (item) => item.contingency,
    }),
    quantity: QuantityWidget,
    hours: Optional(QuantityWidget),
    rate: MoneyWidget,
    certifiedForemanRate: Optional(MoneyWidget),
    projectDescription: ProjectDescriptionDetailWidget,
    nonCfExpense: SwitchWidget,
    schedule: SelectLinkWidget({
        meta: PROJECT_SCHEDULE_META,
        label(item) {
            return item.description;
        },
    }),
};
export type ExtraProps = {
    dividedDescription: boolean;
    ex: boolean;
};

function validate(data: Data, cache: QuickCacheApi) {
    const errors = baseValidate(data, cache);
    if (data.description.length > 70) {
        errors.push({ field: "description", invalid: true, empty: false });
    }
    return errors;
}

function reduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
) {
    let inner = baseReduce(state, data, action, context);

    if (isLumpSumUnitType(inner.data.type)) {
        inner = {
            state: inner.state,
            data: {
                ...inner.data,
                quantity: new Decimal(1),
                rate: inner.data.quantity.times(inner.data.rate),
            },
        };
    }
    return inner;
}

function Component(props: Props) {
    const lumpSumUnitType = isLumpSumUnitType(props.data.type);
    const ratedUnitType = !lumpSumUnitType;
    const detailSheet = useRecordContext(DETAIL_SHEET_META);

    return (
        <TableRow flexSizes>
            <widgets.description />
            <widgets.masterFormatCode />
            {detailSheet.revamped && (
                <widgets.schedule records={detailSheet.schedules} />
            )}
            <widgets.nonCfExpense />
            {ratedUnitType ? <widgets.quantity /> : <div />}
            <widgets.type />
            {ratedUnitType ? <widgets.rate /> : <div />}
            {props.ex &&
                (ratedUnitType ? <widgets.certifiedForemanRate /> : <div />)}
            {ratedUnitType ? (
                <MoneyStatic value={calcContingencyItemTotal(props.data)} />
            ) : (
                <widgets.rate />
            )}
            {props.data.type === CONTINGENCY_ALLOWANCE_CONTINGENCY_TYPE ? (
                <widgets.certifiedForemanRate />
            ) : (
                <MoneyStatic
                    value={computeContingencyItemRemdalCost(props.data)}
                />
            )}
        </TableRow>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.description> &
    WidgetContext<typeof Fields.masterFormatCode> &
    WidgetContext<typeof Fields.type> &
    WidgetContext<typeof Fields.quantity> &
    WidgetContext<typeof Fields.hours> &
    WidgetContext<typeof Fields.rate> &
    WidgetContext<typeof Fields.certifiedForemanRate> &
    WidgetContext<typeof Fields.projectDescription> &
    WidgetContext<typeof Fields.nonCfExpense> &
    WidgetContext<typeof Fields.schedule>;
type BaseState = {
    description: WidgetState<typeof Fields.description>;
    masterFormatCode: WidgetState<typeof Fields.masterFormatCode>;
    type: WidgetState<typeof Fields.type>;
    quantity: WidgetState<typeof Fields.quantity>;
    hours: WidgetState<typeof Fields.hours>;
    rate: WidgetState<typeof Fields.rate>;
    certifiedForemanRate: WidgetState<typeof Fields.certifiedForemanRate>;
    projectDescription: WidgetState<typeof Fields.projectDescription>;
    nonCfExpense: WidgetState<typeof Fields.nonCfExpense>;
    schedule: WidgetState<typeof Fields.schedule>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | { type: "DESCRIPTION"; action: WidgetAction<typeof Fields.description> }
    | {
          type: "MASTER_FORMAT_CODE";
          action: WidgetAction<typeof Fields.masterFormatCode>;
      }
    | { type: "TYPE"; action: WidgetAction<typeof Fields.type> }
    | { type: "QUANTITY"; action: WidgetAction<typeof Fields.quantity> }
    | { type: "HOURS"; action: WidgetAction<typeof Fields.hours> }
    | { type: "RATE"; action: WidgetAction<typeof Fields.rate> }
    | {
          type: "CERTIFIED_FOREMAN_RATE";
          action: WidgetAction<typeof Fields.certifiedForemanRate>;
      }
    | {
          type: "PROJECT_DESCRIPTION";
          action: WidgetAction<typeof Fields.projectDescription>;
      }
    | {
          type: "NON_CF_EXPENSE";
          action: WidgetAction<typeof Fields.nonCfExpense>;
      }
    | { type: "SCHEDULE"; action: WidgetAction<typeof Fields.schedule> };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps> & ExtraProps;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(
        Fields.description,
        data.description,
        cache,
        "description",
        errors
    );
    subvalidate(
        Fields.masterFormatCode,
        data.masterFormatCode,
        cache,
        "masterFormatCode",
        errors
    );
    subvalidate(Fields.type, data.type, cache, "type", errors);
    subvalidate(Fields.quantity, data.quantity, cache, "quantity", errors);
    subvalidate(Fields.hours, data.hours, cache, "hours", errors);
    subvalidate(Fields.rate, data.rate, cache, "rate", errors);
    subvalidate(
        Fields.certifiedForemanRate,
        data.certifiedForemanRate,
        cache,
        "certifiedForemanRate",
        errors
    );
    subvalidate(
        Fields.projectDescription,
        data.projectDescription,
        cache,
        "projectDescription",
        errors
    );
    subvalidate(
        Fields.nonCfExpense,
        data.nonCfExpense,
        cache,
        "nonCfExpense",
        errors
    );
    subvalidate(Fields.schedule, data.schedule, cache, "schedule", errors);
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "DESCRIPTION": {
            const inner = Fields.description.reduce(
                state.description,
                data.description,
                action.action,
                subcontext
            );
            return {
                state: { ...state, description: inner.state },
                data: { ...data, description: inner.data },
            };
        }
        case "MASTER_FORMAT_CODE": {
            const inner = Fields.masterFormatCode.reduce(
                state.masterFormatCode,
                data.masterFormatCode,
                action.action,
                subcontext
            );
            return {
                state: { ...state, masterFormatCode: inner.state },
                data: { ...data, masterFormatCode: inner.data },
            };
        }
        case "TYPE": {
            const inner = Fields.type.reduce(
                state.type,
                data.type,
                action.action,
                subcontext
            );
            return {
                state: { ...state, type: inner.state },
                data: { ...data, type: inner.data },
            };
        }
        case "QUANTITY": {
            const inner = Fields.quantity.reduce(
                state.quantity,
                data.quantity,
                action.action,
                subcontext
            );
            return {
                state: { ...state, quantity: inner.state },
                data: { ...data, quantity: inner.data },
            };
        }
        case "HOURS": {
            const inner = Fields.hours.reduce(
                state.hours,
                data.hours,
                action.action,
                subcontext
            );
            return {
                state: { ...state, hours: inner.state },
                data: { ...data, hours: inner.data },
            };
        }
        case "RATE": {
            const inner = Fields.rate.reduce(
                state.rate,
                data.rate,
                action.action,
                subcontext
            );
            return {
                state: { ...state, rate: inner.state },
                data: { ...data, rate: inner.data },
            };
        }
        case "CERTIFIED_FOREMAN_RATE": {
            const inner = Fields.certifiedForemanRate.reduce(
                state.certifiedForemanRate,
                data.certifiedForemanRate,
                action.action,
                subcontext
            );
            return {
                state: { ...state, certifiedForemanRate: inner.state },
                data: { ...data, certifiedForemanRate: inner.data },
            };
        }
        case "PROJECT_DESCRIPTION": {
            const inner = Fields.projectDescription.reduce(
                state.projectDescription,
                data.projectDescription,
                action.action,
                subcontext
            );
            return {
                state: { ...state, projectDescription: inner.state },
                data: { ...data, projectDescription: inner.data },
            };
        }
        case "NON_CF_EXPENSE": {
            const inner = Fields.nonCfExpense.reduce(
                state.nonCfExpense,
                data.nonCfExpense,
                action.action,
                subcontext
            );
            return {
                state: { ...state, nonCfExpense: inner.state },
                data: { ...data, nonCfExpense: inner.data },
            };
        }
        case "SCHEDULE": {
            const inner = Fields.schedule.reduce(
                state.schedule,
                data.schedule,
                action.action,
                subcontext
            );
            return {
                state: { ...state, schedule: inner.state },
                data: { ...data, schedule: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    description: function (
        props: WidgetExtraProps<typeof Fields.description> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "DESCRIPTION",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "description", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.description.component
                state={context.state.description}
                data={context.data.description}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Description"}
            />
        );
    },
    masterFormatCode: function (
        props: WidgetExtraProps<typeof Fields.masterFormatCode> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "MASTER_FORMAT_CODE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "masterFormatCode", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.masterFormatCode.component
                state={context.state.masterFormatCode}
                data={context.data.masterFormatCode}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Master Format Code"}
            />
        );
    },
    type: function (
        props: WidgetExtraProps<typeof Fields.type> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({ type: "TYPE", action }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "type", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.type.component
                state={context.state.type}
                data={context.data.type}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Type"}
            />
        );
    },
    quantity: function (
        props: WidgetExtraProps<typeof Fields.quantity> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "QUANTITY",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "quantity", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.quantity.component
                state={context.state.quantity}
                data={context.data.quantity}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Quantity"}
            />
        );
    },
    hours: function (
        props: WidgetExtraProps<typeof Fields.hours> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({ type: "HOURS", action }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "hours", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.hours.component
                state={context.state.hours}
                data={context.data.hours}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Hours"}
            />
        );
    },
    rate: function (
        props: WidgetExtraProps<typeof Fields.rate> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({ type: "RATE", action }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "rate", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.rate.component
                state={context.state.rate}
                data={context.data.rate}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Rate"}
            />
        );
    },
    certifiedForemanRate: function (
        props: WidgetExtraProps<typeof Fields.certifiedForemanRate> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "CERTIFIED_FOREMAN_RATE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "certifiedForemanRate",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.certifiedForemanRate.component
                state={context.state.certifiedForemanRate}
                data={context.data.certifiedForemanRate}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Certified Foreman Rate"}
            />
        );
    },
    projectDescription: function (
        props: WidgetExtraProps<typeof Fields.projectDescription> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PROJECT_DESCRIPTION",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "projectDescription",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.projectDescription.component
                state={context.state.projectDescription}
                data={context.data.projectDescription}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Project Description"}
            />
        );
    },
    nonCfExpense: function (
        props: WidgetExtraProps<typeof Fields.nonCfExpense> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "NON_CF_EXPENSE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "nonCfExpense", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.nonCfExpense.component
                state={context.state.nonCfExpense}
                data={context.data.nonCfExpense}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Non Cf Expense"}
            />
        );
    },
    schedule: function (
        props: WidgetExtraProps<typeof Fields.schedule> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "SCHEDULE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "schedule", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.schedule.component
                state={context.state.schedule}
                data={context.data.schedule}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Schedule"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: CONTINGENCY_ITEM_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let descriptionState;
        {
            const inner = Fields.description.initialize(
                data.description,
                subcontext,
                subparameters.description
            );
            descriptionState = inner.state;
            data = { ...data, description: inner.data };
        }
        let masterFormatCodeState;
        {
            const inner = Fields.masterFormatCode.initialize(
                data.masterFormatCode,
                subcontext,
                subparameters.masterFormatCode
            );
            masterFormatCodeState = inner.state;
            data = { ...data, masterFormatCode: inner.data };
        }
        let typeState;
        {
            const inner = Fields.type.initialize(
                data.type,
                subcontext,
                subparameters.type
            );
            typeState = inner.state;
            data = { ...data, type: inner.data };
        }
        let quantityState;
        {
            const inner = Fields.quantity.initialize(
                data.quantity,
                subcontext,
                subparameters.quantity
            );
            quantityState = inner.state;
            data = { ...data, quantity: inner.data };
        }
        let hoursState;
        {
            const inner = Fields.hours.initialize(
                data.hours,
                subcontext,
                subparameters.hours
            );
            hoursState = inner.state;
            data = { ...data, hours: inner.data };
        }
        let rateState;
        {
            const inner = Fields.rate.initialize(
                data.rate,
                subcontext,
                subparameters.rate
            );
            rateState = inner.state;
            data = { ...data, rate: inner.data };
        }
        let certifiedForemanRateState;
        {
            const inner = Fields.certifiedForemanRate.initialize(
                data.certifiedForemanRate,
                subcontext,
                subparameters.certifiedForemanRate
            );
            certifiedForemanRateState = inner.state;
            data = { ...data, certifiedForemanRate: inner.data };
        }
        let projectDescriptionState;
        {
            const inner = Fields.projectDescription.initialize(
                data.projectDescription,
                subcontext,
                subparameters.projectDescription
            );
            projectDescriptionState = inner.state;
            data = { ...data, projectDescription: inner.data };
        }
        let nonCfExpenseState;
        {
            const inner = Fields.nonCfExpense.initialize(
                data.nonCfExpense,
                subcontext,
                subparameters.nonCfExpense
            );
            nonCfExpenseState = inner.state;
            data = { ...data, nonCfExpense: inner.data };
        }
        let scheduleState;
        {
            const inner = Fields.schedule.initialize(
                data.schedule,
                subcontext,
                subparameters.schedule
            );
            scheduleState = inner.state;
            data = { ...data, schedule: inner.data };
        }
        let state = {
            initialParameters: parameters,
            description: descriptionState,
            masterFormatCode: masterFormatCodeState,
            type: typeState,
            quantity: quantityState,
            hours: hoursState,
            rate: rateState,
            certifiedForemanRate: certifiedForemanRateState,
            projectDescription: projectDescriptionState,
            nonCfExpense: nonCfExpenseState,
            schedule: scheduleState,
        };
        return {
            state,
            data,
        };
    },
    validate: validate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={CONTINGENCY_ITEM_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: reduce,
};
export default Widget;
type Widgets = {
    description: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.description>
    >;
    masterFormatCode: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.masterFormatCode>
    >;
    type: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.type>
    >;
    quantity: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.quantity>
    >;
    hours: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.hours>
    >;
    rate: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.rate>
    >;
    certifiedForemanRate: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.certifiedForemanRate>
    >;
    projectDescription: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.projectDescription>
    >;
    nonCfExpense: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.nonCfExpense>
    >;
    schedule: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.schedule>
    >;
};
// END MAGIC -- DO NOT EDIT
