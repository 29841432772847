import * as React from "react";
import { Table } from "react-bootstrap";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { SaveDeleteButton } from "../../../clay/save-delete-button";
import { FormField } from "../../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { ListWidget } from "../../../clay/widgets/ListWidget";
import { SimpleListWrapper } from "../../../clay/widgets/SimpleListWrapper";
import { SwitchWidget } from "../../../clay/widgets/SwitchWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { RequiredEquipmentType, REQUIRED_EQUIPMENT_TYPE_META } from "./table";

export type Data = RequiredEquipmentType;

export const Fields = {
    name: FormField(TextWidget),
    sizes: ListWidget(SimpleListWrapper(TextWidget), { emptyOk: true }),
    showNumberOfDrops: FormField(SwitchWidget),
    showNumberOfStages: FormField(SwitchWidget),
    showBuildingHeight: FormField(SwitchWidget),
};

function Component(props: Props) {
    return (
        <>
            <widgets.name />
            <Table>
                <thead>
                    <th colSpan={3}>Sizes</th>
                </thead>
                <widgets.sizes extraItemForAdd containerClass="tbody" />
            </Table>
            <widgets.showNumberOfDrops />
            <widgets.showNumberOfStages />
            <widgets.showBuildingHeight />

            <SaveDeleteButton />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.name> &
    WidgetContext<typeof Fields.sizes> &
    WidgetContext<typeof Fields.showNumberOfDrops> &
    WidgetContext<typeof Fields.showNumberOfStages> &
    WidgetContext<typeof Fields.showBuildingHeight>;
type ExtraProps = {};
type BaseState = {
    name: WidgetState<typeof Fields.name>;
    sizes: WidgetState<typeof Fields.sizes>;
    showNumberOfDrops: WidgetState<typeof Fields.showNumberOfDrops>;
    showNumberOfStages: WidgetState<typeof Fields.showNumberOfStages>;
    showBuildingHeight: WidgetState<typeof Fields.showBuildingHeight>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | { type: "NAME"; action: WidgetAction<typeof Fields.name> }
    | { type: "SIZES"; action: WidgetAction<typeof Fields.sizes> }
    | {
          type: "SHOW_NUMBER_OF_DROPS";
          action: WidgetAction<typeof Fields.showNumberOfDrops>;
      }
    | {
          type: "SHOW_NUMBER_OF_STAGES";
          action: WidgetAction<typeof Fields.showNumberOfStages>;
      }
    | {
          type: "SHOW_BUILDING_HEIGHT";
          action: WidgetAction<typeof Fields.showBuildingHeight>;
      };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(Fields.name, data.name, cache, "name", errors);
    subvalidate(Fields.sizes, data.sizes, cache, "sizes", errors);
    subvalidate(
        Fields.showNumberOfDrops,
        data.showNumberOfDrops,
        cache,
        "showNumberOfDrops",
        errors
    );
    subvalidate(
        Fields.showNumberOfStages,
        data.showNumberOfStages,
        cache,
        "showNumberOfStages",
        errors
    );
    subvalidate(
        Fields.showBuildingHeight,
        data.showBuildingHeight,
        cache,
        "showBuildingHeight",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = Fields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "SIZES": {
            const inner = Fields.sizes.reduce(
                state.sizes,
                data.sizes,
                action.action,
                subcontext
            );
            return {
                state: { ...state, sizes: inner.state },
                data: { ...data, sizes: inner.data },
            };
        }
        case "SHOW_NUMBER_OF_DROPS": {
            const inner = Fields.showNumberOfDrops.reduce(
                state.showNumberOfDrops,
                data.showNumberOfDrops,
                action.action,
                subcontext
            );
            return {
                state: { ...state, showNumberOfDrops: inner.state },
                data: { ...data, showNumberOfDrops: inner.data },
            };
        }
        case "SHOW_NUMBER_OF_STAGES": {
            const inner = Fields.showNumberOfStages.reduce(
                state.showNumberOfStages,
                data.showNumberOfStages,
                action.action,
                subcontext
            );
            return {
                state: { ...state, showNumberOfStages: inner.state },
                data: { ...data, showNumberOfStages: inner.data },
            };
        }
        case "SHOW_BUILDING_HEIGHT": {
            const inner = Fields.showBuildingHeight.reduce(
                state.showBuildingHeight,
                data.showBuildingHeight,
                action.action,
                subcontext
            );
            return {
                state: { ...state, showBuildingHeight: inner.state },
                data: { ...data, showBuildingHeight: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    name: function (
        props: WidgetExtraProps<typeof Fields.name> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({ type: "NAME", action }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.name.component
                state={context.state.name}
                data={context.data.name}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Name"}
            />
        );
    },
    sizes: function (
        props: WidgetExtraProps<typeof Fields.sizes> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({ type: "SIZES", action }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "sizes", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.sizes.component
                state={context.state.sizes}
                data={context.data.sizes}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Sizes"}
            />
        );
    },
    showNumberOfDrops: function (
        props: WidgetExtraProps<typeof Fields.showNumberOfDrops> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "SHOW_NUMBER_OF_DROPS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "showNumberOfDrops",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.showNumberOfDrops.component
                state={context.state.showNumberOfDrops}
                data={context.data.showNumberOfDrops}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Show Number of Drops"}
            />
        );
    },
    showNumberOfStages: function (
        props: WidgetExtraProps<typeof Fields.showNumberOfStages> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "SHOW_NUMBER_OF_STAGES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "showNumberOfStages",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.showNumberOfStages.component
                state={context.state.showNumberOfStages}
                data={context.data.showNumberOfStages}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Show Number of Stages"}
            />
        );
    },
    showBuildingHeight: function (
        props: WidgetExtraProps<typeof Fields.showBuildingHeight> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "SHOW_BUILDING_HEIGHT",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "showBuildingHeight",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.showBuildingHeight.component
                state={context.state.showBuildingHeight}
                data={context.data.showBuildingHeight}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Show Building Height"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: REQUIRED_EQUIPMENT_TYPE_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let nameState;
        {
            const inner = Fields.name.initialize(
                data.name,
                subcontext,
                subparameters.name
            );
            nameState = inner.state;
            data = { ...data, name: inner.data };
        }
        let sizesState;
        {
            const inner = Fields.sizes.initialize(
                data.sizes,
                subcontext,
                subparameters.sizes
            );
            sizesState = inner.state;
            data = { ...data, sizes: inner.data };
        }
        let showNumberOfDropsState;
        {
            const inner = Fields.showNumberOfDrops.initialize(
                data.showNumberOfDrops,
                subcontext,
                subparameters.showNumberOfDrops
            );
            showNumberOfDropsState = inner.state;
            data = { ...data, showNumberOfDrops: inner.data };
        }
        let showNumberOfStagesState;
        {
            const inner = Fields.showNumberOfStages.initialize(
                data.showNumberOfStages,
                subcontext,
                subparameters.showNumberOfStages
            );
            showNumberOfStagesState = inner.state;
            data = { ...data, showNumberOfStages: inner.data };
        }
        let showBuildingHeightState;
        {
            const inner = Fields.showBuildingHeight.initialize(
                data.showBuildingHeight,
                subcontext,
                subparameters.showBuildingHeight
            );
            showBuildingHeightState = inner.state;
            data = { ...data, showBuildingHeight: inner.data };
        }
        let state = {
            initialParameters: parameters,
            name: nameState,
            sizes: sizesState,
            showNumberOfDrops: showNumberOfDropsState,
            showNumberOfStages: showNumberOfStagesState,
            showBuildingHeight: showBuildingHeightState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext
                    meta={REQUIRED_EQUIPMENT_TYPE_META}
                    value={props.data}
                >
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    name: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.name>
    >;
    sizes: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.sizes>
    >;
    showNumberOfDrops: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.showNumberOfDrops>
    >;
    showNumberOfStages: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.showNumberOfStages>
    >;
    showBuildingHeight: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.showBuildingHeight>
    >;
};
// END MAGIC -- DO NOT EDIT
