import { Quantity } from "../../../clay/common";
import { sumMap } from "../../../clay/queryFuncs";
import { MoneyStatic } from "../../../clay/widgets/money-widget";
import { QuantityStatic } from "../../../clay/widgets/number-widget";
import * as React from "react";

function ShowColumn<T extends { nonCfExpense: boolean }>(props: {
    column: { value?: (item: T) => Quantity; money?: boolean };
    items: T[];
    filter: (data: T) => boolean;
}) {
    if (!props.column.value) {
        return <th></th>;
    }

    const value = sumMap(props.items.filter(props.filter), props.column.value);

    const Component = props.column.money ? MoneyStatic : QuantityStatic;

    return (
        <th>
            <Component style={{ fontWeight: "bold" }} value={value} />
        </th>
    );
}

export function TotalsSection<T extends { nonCfExpense: boolean }>(props: {
    items: T[];
    columns: {
        value?: (item: T) => Quantity;
        money?: boolean;
    }[];
}) {
    return (
        <tfoot>
            <tr style={{ height: "1em" }} />
            <tr
                style={{
                    height: "1em",
                    borderTop: "solid 4px black",
                }}
            >
                <td colSpan={props.columns.length}></td>
            </tr>
            <tr>
                <th />
                <th colSpan={1}>CF Amount</th>
                {props.columns.map((column, index) => (
                    <ShowColumn
                        key={index}
                        column={column}
                        items={props.items}
                        filter={(item) => !item.nonCfExpense}
                    />
                ))}
            </tr>
            <tr>
                <th />
                <th colSpan={1}>Non CF Amount</th>
                {props.columns.map((column, index) => (
                    <ShowColumn
                        key={index}
                        column={column}
                        items={props.items}
                        filter={(item) => item.nonCfExpense}
                    />
                ))}
            </tr>
            <tr>
                <th />
                <th colSpan={1}>Total</th>
                {props.columns.map((column, index) => (
                    <ShowColumn
                        key={index}
                        column={column}
                        items={props.items}
                        filter={() => true}
                    />
                ))}
            </tr>
        </tfoot>
    );
}
