import { AdminCollectionPage } from "../../../../clay/admin-collection-page";
import Widget from "./Widget.widget";

export const SiteVisitReportTemplatePage = AdminCollectionPage({
    meta: Widget,
    labelColumn: "name",
    urlPrefix: "#/admin/site-visit-report-templates",
    title: "Site Visit Report Templates",
    adminCategory: "projects",
});
