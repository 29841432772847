import Decimal from "decimal.js";
import { Money, Quantity } from "../../../clay/common";
import { Link } from "../../../clay/link";
import { sumMap } from "../../../clay/queryFuncs";
import {
    calcContingencyItemTotal,
    computeContingencyItemRemdalCost,
    isLumpSumUnitType,
} from "../../contingency/table";
import { ItemType } from "../../estimate/types/table";
import { User } from "../../user/table";
import { MasterFormatCode } from "../master-format-codes/table";
import {
    CONTINGENCY_SPECTURM_ID,
    LABOUR_SPECTRUM_ID,
    MATERIALS_SPECTRUM_ID,
    SpectrumInvoiceType,
} from "../spectrum/table";
import {
    calcBudgetHourTotal,
    calcBudgetMaterialTotal,
    calcDetailSheetOptionOverallTotal,
    DetailSheet,
} from "./table";

export type DetailSheetLineSubItem = {
    category: Link<SpectrumInvoiceType>;
    quantity: Quantity | null;
    cost: Money;
    price: Money;
};

export type DetailSheetLineItem = {
    certifiedForeman: Link<User>;
    masterFormatCode: Link<MasterFormatCode>;
    itemType: Link<ItemType>;
    name: string;
    invoicingType: "fixed" | "unit-rate" | "t&m";
    source: "budget" | "allowance" | "contingency";
    subItems: DetailSheetLineSubItem[];
    nonCfExpense: boolean;
};

export type SpectrumLine = {
    invoicingType: "fixed" | "unit-rate" | "t&m";
    spectrumInvoiceType: string;
    phaseCode: string;
    phaseCodeDescription: string;
    cost: Money;
    price: Money;
    hours: Quantity;
};

export function computeSpectrumItems(
    detailSheets: DetailSheet[],
    users: User[],
    masterFormatCodes: MasterFormatCode[],
    spectrumInvoiceType: SpectrumInvoiceType[],
    targetChangeOrder: Link<DetailSheet>
) {
    const rows: SpectrumLine[] = [];
    let used = new Set<string>();
    let known = new Map<string, number>();
    for (const detailSheet of detailSheets) {
        visitDetailSheetItems(detailSheet, (item) => {
            const masterFormatCodeRecord = masterFormatCodes.find(
                (x) => x.id.uuid === item.masterFormatCode
            );

            let masterFormatCode = masterFormatCodeRecord?.code;

            if (item.source === "contingency" && masterFormatCode) {
                let current = new Decimal(masterFormatCode).plus(1);
                while (used.has(current.toString())) {
                    current = current.plus(1);
                }
                used.add(current.toString());
                masterFormatCode = current.toString().padStart(6, "0");
            }
            if (!masterFormatCode) {
                masterFormatCode = "??????";
            }

            const invoiceCode =
                item.invoicingType === "fixed"
                    ? "1"
                    : item.invoicingType === "t&m"
                    ? "3"
                    : "2";

            const mainPhaseCode =
                determineCfCode(item, users) + masterFormatCode + invoiceCode;

            if (
                (targetChangeOrder === null && !detailSheet.change) ||
                targetChangeOrder === detailSheet.id.uuid
            ) {
                for (const subitem of item.subItems) {
                    const categoryCode = spectrumInvoiceType.find(
                        (x) => x.id.uuid === subitem.category
                    );

                    const phaseCode =
                        subitem.category == CONTINGENCY_SPECTURM_ID
                            ? "00" + mainPhaseCode.substring(2)
                            : mainPhaseCode;

                    const key = phaseCode + subitem.category;
                    const index = known.get(key);
                    if (index !== undefined) {
                        const row = rows[index];
                        row.cost = row.cost.plus(subitem.cost);
                        row.price = row.price.plus(subitem.price);
                        if (subitem.quantity) {
                            row.hours = row.hours.plus(subitem.quantity);
                        }
                    } else {
                        known.set(key, rows.length);
                        rows.push({
                            cost: subitem.cost,
                            price: subitem.price,
                            hours: subitem.quantity || new Decimal(0),
                            invoicingType: item.invoicingType,
                            phaseCode,
                            phaseCodeDescription:
                                masterFormatCodeRecord?.description || "???",
                            spectrumInvoiceType: categoryCode?.code || "?",
                        });
                    }
                }
            }
        });
    }

    return rows;
}

export function visitDetailSheetItems(
    detailSheet: DetailSheet,
    f: (item: DetailSheetLineItem) => void
) {
    let totalBudgetCost = sumMap(detailSheet.options, (option) =>
        calcDetailSheetOptionOverallTotal(option)
    );
    let totalBudgetPrice = sumMap(
        detailSheet.schedules,
        (schedule) => schedule.price
    ).minus(
        sumMap(detailSheet.options, (option) =>
            sumMap(option.allowances, (allowance) => allowance.price)
        )
    );

    const allocateCost = (cost: Decimal) => {
        const price = cost
            .dividedBy(totalBudgetCost)
            .times(totalBudgetPrice)
            .toDecimalPlaces(2);
        totalBudgetCost = totalBudgetCost.minus(cost);
        totalBudgetPrice = totalBudgetPrice.minus(price);

        return {
            cost,
            price,
        };
    };

    for (const option of detailSheet.options) {
        for (const line of option.budget) {
            f({
                certifiedForeman: line.nonCfExpense
                    ? null
                    : detailSheet.certifiedForeman,
                nonCfExpense: line.nonCfExpense,
                masterFormatCode: line.masterFormatCode,
                itemType: line.itemType,
                name: line.name,
                source: "budget",
                invoicingType: "fixed",
                subItems: [
                    {
                        category: MATERIALS_SPECTRUM_ID,
                        ...allocateCost(calcBudgetMaterialTotal(line)),
                        quantity: new Decimal(0),
                    },
                    {
                        category: LABOUR_SPECTRUM_ID,
                        ...allocateCost(calcBudgetHourTotal(line)),
                        quantity: line.hours,
                    },
                ],
            });
        }
        for (const allowance of option.allowances) {
            f({
                certifiedForeman: allowance.nonCfExpense
                    ? null
                    : detailSheet.certifiedForeman,
                nonCfExpense: allowance.nonCfExpense,
                masterFormatCode: allowance.masterFormatCode,
                itemType: allowance.itemType,
                name: allowance.name,
                source: "allowance",
                invoicingType: "fixed",
                subItems: [
                    {
                        category: allowance.spectrumType,
                        cost: allowance.cost,
                        price: allowance.price,
                        quantity: new Decimal(0),
                    },
                ],
            });
        }
    }
    for (const contingencyItem of detailSheet.contingencyItems) {
        const invoicingType = isLumpSumUnitType(contingencyItem.type)
            ? "t&m"
            : "unit-rate";
        f({
            certifiedForeman: contingencyItem.nonCfExpense
                ? null
                : detailSheet.certifiedForeman,
            nonCfExpense: contingencyItem.nonCfExpense,
            masterFormatCode: contingencyItem.masterFormatCode,
            itemType: contingencyItem.itemType,
            name: contingencyItem.description,
            source: "contingency",
            invoicingType,
            subItems: [
                {
                    category: MATERIALS_SPECTRUM_ID,
                    cost: new Decimal(0),
                    price: new Decimal(0),
                    quantity: new Decimal(0),
                },
                {
                    category: LABOUR_SPECTRUM_ID,
                    cost: new Decimal(0),
                    price: new Decimal(0),
                    quantity: contingencyItem.hours,
                },
                {
                    category: CONTINGENCY_SPECTURM_ID,
                    cost: computeContingencyItemRemdalCost(contingencyItem),
                    price: calcContingencyItemTotal(contingencyItem),
                    quantity: contingencyItem.quantity,
                },
            ],
        });
    }
}

export function determineCfCode(item: DetailSheetLineItem, users: User[]) {
    if (item.certifiedForeman !== null) {
        const cf = users.find((x) => x.id.uuid === item.certifiedForeman);
        if (!cf) {
            return "??";
        } else {
            return cf.code.substring(1);
        }
    } else {
        return "00";
    }
}
