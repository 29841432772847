import Decimal from "decimal.js";
import * as React from "react";
import { useProjectRecordQuery } from "../../../clay/api";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi, useQuickAllRecords } from "../../../clay/quick-cache";
import {
    RecordContext,
    RecordWidget,
    ValidationError,
    Widget,
    WidgetProps,
    WidgetResult,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { ExportDataTable } from "../accounting/ExportDataTable";
import {
    DetailSheetTimeAndMaterialsRate,
    DETAIL_SHEET_META,
} from "../detail-sheet/table";
import { Project, PROJECT_META } from "../table";
import { BILLING_CODE_META } from "../types/table";

export type Data = Project;

function Component(props: Props) {
    const detailSheets = useProjectRecordQuery(
        DETAIL_SHEET_META,
        props.data.id.uuid
    );

    const billingCodes = useQuickAllRecords(BILLING_CODE_META);

    type Row = DetailSheetTimeAndMaterialsRate;

    const rows = React.useMemo(() => {
        if (!billingCodes || !detailSheets) {
            return undefined;
        }

        const rows: Row[] = [];

        for (const detailSheet of detailSheets) {
            for (const rate of detailSheet.timeAndMaterialRates) {
                rows.push({
                    ...rate,
                    billingCode:
                        billingCodes.find((x) => x.id.uuid === rate.billingCode)
                            ?.code || "",
                });
            }
        }
        return rows;
    }, [detailSheets, billingCodes]);

    const columns = React.useMemo(() => {
        return {
            A() {
                return "";
            },
            B() {
                return "";
            },
            Job_Number() {
                return "10" + props.data.projectNumber?.toString() || "";
            },
            Billing_Code(item) {
                return item.billingCode;
            },
            Description(item) {
                return item.description;
            },
            F(item) {
                return "";
            },
            Reg_Bill_1(item) {
                return item.rate;
            },
            H(item) {
                return "";
            },
            I(item) {
                return "";
            },
            J(item) {
                return "";
            },
            K(item) {
                return "";
            },
            OT_Bill_1(item) {
                return item.rate;
            },
            M(item) {
                return "";
            },
            N(item) {
                return "";
            },
            O(item) {
                return "";
            },
            P(item) {
                return "";
            },
            DT_Bill_1(item) {
                return item.rate;
            },
        } satisfies Record<string, (item: Row) => string | Decimal | null>;
    }, [props.data.projectNumber]);
    return (
        <>
            <ExportDataTable
                rows={rows}
                columns={columns}
                money={new Set(["Reg_Bill_1", "OT_Bill_1", "DT_Bill_1"])}
                name={`Spectrum T&M Export ${
                    props.data.projectNumber
                } - ${new Date().toString()}.xlsx`}
            />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {};
type ExtraProps = {};
type BaseState = {
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction = unknown;

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    return { state, data };
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: PROJECT_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let state = {
            initialParameters: parameters,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={PROJECT_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {};
// END MAGIC -- DO NOT EDIT
