import { isBefore } from "date-fns";
import { css } from "glamor";
import { find } from "lodash";
import Select from "react-select";
import { statusToState, Widget, WidgetStatus } from "../../clay/widgets";
import { selectStyle } from "../../clay/widgets/SelectLinkWidget";
import { SimpleAtomic } from "../../clay/widgets/simple-atomic";
import { hasPermission } from "../../permissions";
import { useUser } from "../state";
import * as React from "react";

const WIDGET_STYLE = css({
    ".is-valid": {
        backgroundPosition: "center right calc(0.375em + 0.7275rem)",
    },
});
export type SelectWidgetAction<T> = {
    type: "SET";
    value: T;
};

type SelectWidgetProps<T> = {
    state: null;
    data: T;
    dispatch: (action: SelectWidgetAction<T>) => void;
    status: WidgetStatus;
    hideStatus?: boolean;
};

type OptionOf<T> = {
    value: T;
    label: string;
};

const INVALID_STYLE = {
    control: (provided: any) => ({
        ...provided,
        borderColor: "#C71C22",
    }),
};

function seasonSelectStyle(invalid: boolean) {
    return {
        ...selectStyle(invalid),
        option: (styles: any, data: any) => {
            const props = { ...styles };
            if (!data.data.current && !data.isSelected) {
                props.fontStyle = "italic";
                props.backgroundColor = "#eaeaea";
            }
            return props;
        },
    };
}

export const SeasonWidget: Widget<
    null,
    string,
    {},
    SelectWidgetAction<string>,
    {
        hideStatus?: boolean;
    }
> = {
    ...SimpleAtomic,
    dataMeta: {
        type: "string",
    },
    initialize(data: string) {
        return {
            state: null,
            data,
        };
    },
    component(props: SelectWidgetProps<string>) {
        const user = useUser();
        const now = new Date();

        const options: { value: string; label: string; current: boolean }[] =
            [];

        const extraSeasons = hasPermission(user, "Project", "extra-seasons");

        const oldOptions: typeof options = [];

        const add = (label: string, ends: Date, include = true) => {
            if (!include) {
                return;
            }
            const current = isBefore(now, ends);
            if (!current && !extraSeasons) {
                return;
            }
            (current ? options : oldOptions).push({
                value: label,
                label,
                current,
            });
        };

        for (let year = now.getFullYear() + 1; year >= 2010; year--) {
            add(
                `Winter ${year}/${year + 1}`,
                new Date(year + 1, 3, 15),
                year < 2024
            );
            add(`Fall ${year}`, new Date(year, 12, 1), year >= 2024);
            add(`Summer ${year}`, new Date(year, 10, 1));
            add(`Winter ${year}`, new Date(year, 3, 15), year >= 2025);
        }

        if (
            options.map((x) => x.value).indexOf(props.data) === -1 &&
            props.data !== ""
        ) {
            options.push({
                value: props.data,
                label: props.data,
                current: false,
            });
        }
        options.reverse();
        options.push(...oldOptions);

        const currentSelected = find(
            options,
            (option) => option.value === props.data
        );
        return (
            <Select
                {...WIDGET_STYLE}
                isDisabled={!props.status.mutable}
                value={currentSelected}
                onChange={(selected) => {
                    if (selected) {
                        props.dispatch({
                            type: "SET",
                            value: (selected as OptionOf<string>).value,
                        });
                    }
                }}
                styles={seasonSelectStyle(props.status.validation.length > 0)}
                className={
                    props.hideStatus
                        ? ""
                        : statusToState(
                              props.status.validation,
                              props.data === null
                          )
                }
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                options={options}
                menuPlacement="auto"
            />
        );
    },
    reduce(
        state: null,
        data: string | null,
        action: SelectWidgetAction<string>,
        context: {}
    ) {
        switch (action.type) {
            case "SET":
                return {
                    state: null,
                    data: action.value,
                    requests: [],
                };
        }
    },
    validate(data: string) {
        if (data !== "") {
            return [];
        } else {
            return [
                {
                    empty: true,
                    invalid: false,
                },
            ];
        }
    },
};
