import { Link } from "../../../../clay/link";
import { RecordMeta } from "../../../../clay/meta";
import { genUUID, UUID } from "../../../../clay/uuid";
import { Version } from "../../../../clay/version";
import {
    JSONToSurveySection,
    ProjectDescriptionCategory,
    repairSurveySectionJSON,
    SurveySection,
    SurveySectionJSON,
    SurveySectionToJSON,
    SURVEY_SECTION_META,
} from "../../../project-description/table";

//!Data
export type SiteVisitReportTemplate = {
    id: UUID;
    recordVersion: Version;
    name: string;
    surveySections: SurveySection[];
    projectDescriptionCategory: Link<ProjectDescriptionCategory>;
    sendToCertifiedForemen: boolean;
    showSummary: boolean;
};

// BEGIN MAGIC -- DO NOT EDIT
export type SiteVisitReportTemplateJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    surveySections: SurveySectionJSON[];
    projectDescriptionCategory: string | null;
    sendToCertifiedForemen: boolean;
    showSummary: boolean;
};

export function JSONToSiteVisitReportTemplate(
    json: SiteVisitReportTemplateJSON
): SiteVisitReportTemplate {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        surveySections: json.surveySections.map((inner) =>
            JSONToSurveySection(inner)
        ),
        projectDescriptionCategory: json.projectDescriptionCategory,
        sendToCertifiedForemen: json.sendToCertifiedForemen,
        showSummary: json.showSummary,
    };
}
export type SiteVisitReportTemplateBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    surveySections?: SurveySectionJSON[];
    projectDescriptionCategory?: string | null;
    sendToCertifiedForemen?: boolean;
    showSummary?: boolean;
};

export function newSiteVisitReportTemplate(): SiteVisitReportTemplate {
    return JSONToSiteVisitReportTemplate(
        repairSiteVisitReportTemplateJSON(undefined)
    );
}
export function repairSiteVisitReportTemplateJSON(
    json: SiteVisitReportTemplateBrokenJSON | undefined
): SiteVisitReportTemplateJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            surveySections: (json.surveySections || []).map((inner) =>
                repairSurveySectionJSON(inner)
            ),
            projectDescriptionCategory: json.projectDescriptionCategory || null,
            sendToCertifiedForemen: json.sendToCertifiedForemen || false,
            showSummary: json.showSummary || false,
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            surveySections: (undefined || []).map((inner) =>
                repairSurveySectionJSON(inner)
            ),
            projectDescriptionCategory: undefined || null,
            sendToCertifiedForemen: undefined || false,
            showSummary: undefined || false,
        };
    }
}

export function SiteVisitReportTemplateToJSON(
    value: SiteVisitReportTemplate
): SiteVisitReportTemplateJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        surveySections: value.surveySections.map((inner) =>
            SurveySectionToJSON(inner)
        ),
        projectDescriptionCategory: value.projectDescriptionCategory,
        sendToCertifiedForemen: value.sendToCertifiedForemen,
        showSummary: value.showSummary,
    };
}

export const SITE_VISIT_REPORT_TEMPLATE_META: RecordMeta<
    SiteVisitReportTemplate,
    SiteVisitReportTemplateJSON,
    SiteVisitReportTemplateBrokenJSON
> & { name: "SiteVisitReportTemplate" } = {
    name: "SiteVisitReportTemplate",
    type: "record",
    repair: repairSiteVisitReportTemplateJSON,
    toJSON: SiteVisitReportTemplateToJSON,
    fromJSON: JSONToSiteVisitReportTemplate,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        surveySections: { type: "array", items: SURVEY_SECTION_META },
        projectDescriptionCategory: {
            type: "uuid",
            linkTo: "ProjectDescriptionCategory",
        },
        sendToCertifiedForemen: { type: "boolean" },
        showSummary: { type: "boolean" },
    },
    userFacingKey: "name",
    functions: {},
    segments: {},
};

// END MAGIC -- DO NOT EDIT
