import { RecordMeta } from "../../../../clay/meta";
import { genUUID, UUID } from "../../../../clay/uuid";
import { Version } from "../../../../clay/version";
import {
    JSONToSurveySection,
    repairSurveySectionJSON,
    SurveySection,
    SurveySectionJSON,
    SurveySectionToJSON,
    SURVEY_SECTION_META,
} from "../../../project-description/table";

//!Data
export type CertifiedForemanAssessmentTemplate = {
    id: UUID;
    recordVersion: Version;
    name: string;
    surveySections: SurveySection[];
};

// BEGIN MAGIC -- DO NOT EDIT
export type CertifiedForemanAssessmentTemplateJSON = {
    id: string;
    recordVersion: number | null;
    name: string;
    surveySections: SurveySectionJSON[];
};

export function JSONToCertifiedForemanAssessmentTemplate(
    json: CertifiedForemanAssessmentTemplateJSON
): CertifiedForemanAssessmentTemplate {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        name: json.name,
        surveySections: json.surveySections.map((inner) =>
            JSONToSurveySection(inner)
        ),
    };
}
export type CertifiedForemanAssessmentTemplateBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    name?: string;
    surveySections?: SurveySectionJSON[];
};

export function newCertifiedForemanAssessmentTemplate(): CertifiedForemanAssessmentTemplate {
    return JSONToCertifiedForemanAssessmentTemplate(
        repairCertifiedForemanAssessmentTemplateJSON(undefined)
    );
}
export function repairCertifiedForemanAssessmentTemplateJSON(
    json: CertifiedForemanAssessmentTemplateBrokenJSON | undefined
): CertifiedForemanAssessmentTemplateJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            name: json.name || "",
            surveySections: (json.surveySections || []).map((inner) =>
                repairSurveySectionJSON(inner)
            ),
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            name: undefined || "",
            surveySections: (undefined || []).map((inner) =>
                repairSurveySectionJSON(inner)
            ),
        };
    }
}

export function CertifiedForemanAssessmentTemplateToJSON(
    value: CertifiedForemanAssessmentTemplate
): CertifiedForemanAssessmentTemplateJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        name: value.name,
        surveySections: value.surveySections.map((inner) =>
            SurveySectionToJSON(inner)
        ),
    };
}

export const CERTIFIED_FOREMAN_ASSESSMENT_TEMPLATE_META: RecordMeta<
    CertifiedForemanAssessmentTemplate,
    CertifiedForemanAssessmentTemplateJSON,
    CertifiedForemanAssessmentTemplateBrokenJSON
> & { name: "CertifiedForemanAssessmentTemplate" } = {
    name: "CertifiedForemanAssessmentTemplate",
    type: "record",
    repair: repairCertifiedForemanAssessmentTemplateJSON,
    toJSON: CertifiedForemanAssessmentTemplateToJSON,
    fromJSON: JSONToCertifiedForemanAssessmentTemplate,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        name: { type: "string" },
        surveySections: { type: "array", items: SURVEY_SECTION_META },
    },
    userFacingKey: "name",
    functions: {},
    segments: {},
};

// END MAGIC -- DO NOT EDIT
