import { AdminTablePage } from "../../../clay/admin-table";

import Widget from "./Widget.widget";

export const MasterFormatCodesPages = AdminTablePage({
    rowWidget: Widget,
    columns: [
        {
            id: "code",
            label: "Code",
        },
        {
            id: "description",
            label: "Description",
        },
    ],
    compare(lhs, rhs) {
        return lhs.code.localeCompare(rhs.code);
    },
    adminCategory: "project",
});
