import Decimal from "decimal.js";
import * as React from "react";
import Select, { SingleValue } from "react-select";
import { useProjectRecordQuery } from "../../../clay/api";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi, useQuickAllRecords } from "../../../clay/quick-cache";
import { OptionalFormField } from "../../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { RichTextWidget } from "../../rich-text-widget";
import { USER_META } from "../../user/table";
import { computeSpectrumItems, SpectrumLine } from "../detail-sheet/phase-code";
import { DETAIL_SHEET_META } from "../detail-sheet/table";
import { MASTER_FORMAT_CODE_META } from "../master-format-codes/table";
import { SPECTRUM_INVOICE_TYPE_META } from "../spectrum/table";
import { Project, PROJECT_META } from "../table";
import { ExportDataTable } from "./ExportDataTable";

export type Data = Project;

export const Fields = {
    iciNotes: OptionalFormField(RichTextWidget),
};

function Component(props: Props) {
    const detailSheets = useProjectRecordQuery(
        DETAIL_SHEET_META,
        props.data.id.uuid
    );

    const users = useQuickAllRecords(USER_META);
    const masterFormatCodes = useQuickAllRecords(MASTER_FORMAT_CODE_META);
    const spectrumInvoicesTypes = useQuickAllRecords(
        SPECTRUM_INVOICE_TYPE_META
    );

    type SelectRecord = { id: string; number: Decimal };
    const baseSelectRecord: SelectRecord = React.useMemo(
        () => ({ id: "", number: new Decimal(0) }),
        []
    );
    const [selectedOrder, setSelectedOrder] =
        React.useState<SelectRecord>(baseSelectRecord);

    const onSelectChange = React.useCallback(
        (event: SingleValue<SelectRecord>) => {
            if (event !== null) {
                setSelectedOrder(event);
            }
        },
        [setSelectedOrder]
    );

    const rows = React.useMemo(() => {
        if (
            !users ||
            !detailSheets ||
            !masterFormatCodes ||
            !spectrumInvoicesTypes
        ) {
            return undefined;
        }
        return computeSpectrumItems(
            detailSheets,
            users,
            masterFormatCodes,
            spectrumInvoicesTypes,
            selectedOrder.id || null
        );
    }, [
        detailSheets,
        users,
        masterFormatCodes,
        spectrumInvoicesTypes,
        selectedOrder,
    ]);

    const columns = React.useMemo(() => {
        return {
            "Phase Code"(item) {
                return item.phaseCode;
            },
            Description(item) {
                return item.phaseCodeDescription;
            },
            "Estimated Budget"(item) {
                return item.cost;
            },
            "Projected Revenue"(item) {
                return item.price;
            },
        } satisfies Record<string, (item: SpectrumLine) => string | Decimal>;
    }, [props.data.projectNumber]);
    return (
        <>
            <Select
                value={selectedOrder}
                onChange={onSelectChange}
                options={[
                    baseSelectRecord,
                    ...(detailSheets || [])
                        .filter((sheet) => sheet.change)
                        .map(
                            (x) =>
                                ({
                                    id: x.id.uuid,
                                    number: x.number,
                                } satisfies SelectRecord)
                        ),
                ]}
                getOptionValue={(x: SelectRecord) => x.id}
                getOptionLabel={(x: SelectRecord) => {
                    if (x.id === "") {
                        return "Base";
                    } else {
                        return "Change Order #" + x.number.toString();
                    }
                }}
            />
            <ExportDataTable
                money={new Set(["Estimated Budget", "Projected Revenue"])}
                rows={rows}
                columns={columns}
                name={`Spectrum Export ${
                    props.data.projectNumber
                } - ${new Date().toString()}.xlsx`}
            />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.iciNotes>;
type ExtraProps = {};
type BaseState = {
    iciNotes: WidgetState<typeof Fields.iciNotes>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | { type: "ICI_NOTES"; action: WidgetAction<typeof Fields.iciNotes> };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(Fields.iciNotes, data.iciNotes, cache, "iciNotes", errors);
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "ICI_NOTES": {
            const inner = Fields.iciNotes.reduce(
                state.iciNotes,
                data.iciNotes,
                action.action,
                subcontext
            );
            return {
                state: { ...state, iciNotes: inner.state },
                data: { ...data, iciNotes: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    iciNotes: function (
        props: WidgetExtraProps<typeof Fields.iciNotes> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "ICI_NOTES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "iciNotes", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.iciNotes.component
                state={context.state.iciNotes}
                data={context.data.iciNotes}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Ici Notes"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: PROJECT_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let iciNotesState;
        {
            const inner = Fields.iciNotes.initialize(
                data.iciNotes,
                subcontext,
                subparameters.iciNotes
            );
            iciNotesState = inner.state;
            data = { ...data, iciNotes: inner.data };
        }
        let state = {
            initialParameters: parameters,
            iciNotes: iciNotesState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={PROJECT_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    iciNotes: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.iciNotes>
    >;
};
// END MAGIC -- DO NOT EDIT
