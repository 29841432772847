import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { ListWidget } from "../../../clay/widgets/ListWidget";
import { CONTENT_AREA, TABLE_STYLE } from "../../styles";
import { DetailSheet, DETAIL_SHEET_META } from "./table";
import TimeAndMaterialsRateWidget from "./TimeAndMaterialsRate.widget";

export type Data = DetailSheet;

export const Fields = {
    timeAndMaterialRates: ListWidget(TimeAndMaterialsRateWidget, {
        emptyOk: true,
    }),
};

function Component(props: Props) {
    return (
        <>
            <div {...CONTENT_AREA}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        marginTop: "2em",
                        marginBottom: "2em",
                    }}
                >
                    <table
                        {...TABLE_STYLE}
                        style={{
                            width: "100%",
                            marginRight: "auto",
                        }}
                    >
                        <thead>
                            <tr>
                                <th style={{ width: "2em" }} />
                                <th>Billing Code</th>
                                <th>Description</th>
                                <th style={{ width: "10em" }}>Rate</th>
                                <th style={{ width: "10em" }}>CF Rate</th>
                                <th style={{ width: "0em" }}>Non-CF Expense</th>
                                <th style={{ width: "1em" }} />
                            </tr>
                        </thead>
                        <widgets.timeAndMaterialRates
                            containerClass="tbody"
                            extraItemForAdd
                        />
                    </table>
                </div>
            </div>
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.timeAndMaterialRates>;
type ExtraProps = {};
type BaseState = {
    timeAndMaterialRates: WidgetState<typeof Fields.timeAndMaterialRates>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | {
          type: "TIME_AND_MATERIAL_RATES";
          action: WidgetAction<typeof Fields.timeAndMaterialRates>;
      };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(
        Fields.timeAndMaterialRates,
        data.timeAndMaterialRates,
        cache,
        "timeAndMaterialRates",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "TIME_AND_MATERIAL_RATES": {
            const inner = Fields.timeAndMaterialRates.reduce(
                state.timeAndMaterialRates,
                data.timeAndMaterialRates,
                action.action,
                subcontext
            );
            return {
                state: { ...state, timeAndMaterialRates: inner.state },
                data: { ...data, timeAndMaterialRates: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    timeAndMaterialRates: function (
        props: WidgetExtraProps<typeof Fields.timeAndMaterialRates> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "TIME_AND_MATERIAL_RATES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "timeAndMaterialRates",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.timeAndMaterialRates.component
                state={context.state.timeAndMaterialRates}
                data={context.data.timeAndMaterialRates}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Time and Material Rates"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: DETAIL_SHEET_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let timeAndMaterialRatesState;
        {
            const inner = Fields.timeAndMaterialRates.initialize(
                data.timeAndMaterialRates,
                subcontext,
                subparameters.timeAndMaterialRates
            );
            timeAndMaterialRatesState = inner.state;
            data = { ...data, timeAndMaterialRates: inner.data };
        }
        let state = {
            initialParameters: parameters,
            timeAndMaterialRates: timeAndMaterialRatesState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={DETAIL_SHEET_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    timeAndMaterialRates: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.timeAndMaterialRates>
    >;
};
// END MAGIC -- DO NOT EDIT
