import React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { DropdownLinkWidget } from "../../../clay/widgets/dropdown-link-widget";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { MoneyWidget } from "../../../clay/widgets/money-widget";
import { SwitchWidget } from "../../../clay/widgets/SwitchWidget";
import { TableRow } from "../../../clay/widgets/TableRow";
import { TextAreaWidget } from "../../../clay/widgets/TextAreaWidget";
import { BILLING_CODE_META } from "../types/table";
import {
    DetailSheetTimeAndMaterialsRate,
    DETAIL_SHEET_TIME_AND_MATERIALS_RATE_META,
} from "./table";

export type Data = DetailSheetTimeAndMaterialsRate;

const Fields = {
    description: TextAreaWidget,
    rate: MoneyWidget,
    certifiedForemanRate: MoneyWidget,
    billingCode: DropdownLinkWidget({
        meta: BILLING_CODE_META,
        label(data) {
            return data.code;
        },
    }),
    nonCfExpense: SwitchWidget,
};

function reduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    if (
        action.type === "BILLING_CODE" &&
        action.action.type === "SET" &&
        action.action.value
    ) {
        return {
            state,
            data: {
                ...data,
                billingCode: action.action.value.id.uuid,
                rate: action.action.value.rate,
                certifiedForemanRate: action.action.value.certifiedForemanRate,
                description: action.action.value.description,
            },
        };
    }

    return baseReduce(state, data, action, context);
}

function Component(props: Props) {
    return (
        <TableRow flexSizes>
            <widgets.billingCode />
            <widgets.description />
            <widgets.rate />
            <widgets.certifiedForemanRate />
            <widgets.nonCfExpense />
        </TableRow>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.description> &
    WidgetContext<typeof Fields.rate> &
    WidgetContext<typeof Fields.certifiedForemanRate> &
    WidgetContext<typeof Fields.billingCode> &
    WidgetContext<typeof Fields.nonCfExpense>;
type ExtraProps = {};
type BaseState = {
    description: WidgetState<typeof Fields.description>;
    rate: WidgetState<typeof Fields.rate>;
    certifiedForemanRate: WidgetState<typeof Fields.certifiedForemanRate>;
    billingCode: WidgetState<typeof Fields.billingCode>;
    nonCfExpense: WidgetState<typeof Fields.nonCfExpense>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | { type: "DESCRIPTION"; action: WidgetAction<typeof Fields.description> }
    | { type: "RATE"; action: WidgetAction<typeof Fields.rate> }
    | {
          type: "CERTIFIED_FOREMAN_RATE";
          action: WidgetAction<typeof Fields.certifiedForemanRate>;
      }
    | { type: "BILLING_CODE"; action: WidgetAction<typeof Fields.billingCode> }
    | {
          type: "NON_CF_EXPENSE";
          action: WidgetAction<typeof Fields.nonCfExpense>;
      };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(
        Fields.description,
        data.description,
        cache,
        "description",
        errors
    );
    subvalidate(Fields.rate, data.rate, cache, "rate", errors);
    subvalidate(
        Fields.certifiedForemanRate,
        data.certifiedForemanRate,
        cache,
        "certifiedForemanRate",
        errors
    );
    subvalidate(
        Fields.billingCode,
        data.billingCode,
        cache,
        "billingCode",
        errors
    );
    subvalidate(
        Fields.nonCfExpense,
        data.nonCfExpense,
        cache,
        "nonCfExpense",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "DESCRIPTION": {
            const inner = Fields.description.reduce(
                state.description,
                data.description,
                action.action,
                subcontext
            );
            return {
                state: { ...state, description: inner.state },
                data: { ...data, description: inner.data },
            };
        }
        case "RATE": {
            const inner = Fields.rate.reduce(
                state.rate,
                data.rate,
                action.action,
                subcontext
            );
            return {
                state: { ...state, rate: inner.state },
                data: { ...data, rate: inner.data },
            };
        }
        case "CERTIFIED_FOREMAN_RATE": {
            const inner = Fields.certifiedForemanRate.reduce(
                state.certifiedForemanRate,
                data.certifiedForemanRate,
                action.action,
                subcontext
            );
            return {
                state: { ...state, certifiedForemanRate: inner.state },
                data: { ...data, certifiedForemanRate: inner.data },
            };
        }
        case "BILLING_CODE": {
            const inner = Fields.billingCode.reduce(
                state.billingCode,
                data.billingCode,
                action.action,
                subcontext
            );
            return {
                state: { ...state, billingCode: inner.state },
                data: { ...data, billingCode: inner.data },
            };
        }
        case "NON_CF_EXPENSE": {
            const inner = Fields.nonCfExpense.reduce(
                state.nonCfExpense,
                data.nonCfExpense,
                action.action,
                subcontext
            );
            return {
                state: { ...state, nonCfExpense: inner.state },
                data: { ...data, nonCfExpense: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    description: function (
        props: WidgetExtraProps<typeof Fields.description> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "DESCRIPTION",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "description", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.description.component
                state={context.state.description}
                data={context.data.description}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Description"}
            />
        );
    },
    rate: function (
        props: WidgetExtraProps<typeof Fields.rate> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({ type: "RATE", action }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "rate", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.rate.component
                state={context.state.rate}
                data={context.data.rate}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Rate"}
            />
        );
    },
    certifiedForemanRate: function (
        props: WidgetExtraProps<typeof Fields.certifiedForemanRate> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "CERTIFIED_FOREMAN_RATE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "certifiedForemanRate",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.certifiedForemanRate.component
                state={context.state.certifiedForemanRate}
                data={context.data.certifiedForemanRate}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Certified Foreman Rate"}
            />
        );
    },
    billingCode: function (
        props: WidgetExtraProps<typeof Fields.billingCode> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "BILLING_CODE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "billingCode", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.billingCode.component
                state={context.state.billingCode}
                data={context.data.billingCode}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Billing Code"}
            />
        );
    },
    nonCfExpense: function (
        props: WidgetExtraProps<typeof Fields.nonCfExpense> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "NON_CF_EXPENSE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "nonCfExpense", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.nonCfExpense.component
                state={context.state.nonCfExpense}
                data={context.data.nonCfExpense}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Non Cf Expense"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: DETAIL_SHEET_TIME_AND_MATERIALS_RATE_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let descriptionState;
        {
            const inner = Fields.description.initialize(
                data.description,
                subcontext,
                subparameters.description
            );
            descriptionState = inner.state;
            data = { ...data, description: inner.data };
        }
        let rateState;
        {
            const inner = Fields.rate.initialize(
                data.rate,
                subcontext,
                subparameters.rate
            );
            rateState = inner.state;
            data = { ...data, rate: inner.data };
        }
        let certifiedForemanRateState;
        {
            const inner = Fields.certifiedForemanRate.initialize(
                data.certifiedForemanRate,
                subcontext,
                subparameters.certifiedForemanRate
            );
            certifiedForemanRateState = inner.state;
            data = { ...data, certifiedForemanRate: inner.data };
        }
        let billingCodeState;
        {
            const inner = Fields.billingCode.initialize(
                data.billingCode,
                subcontext,
                subparameters.billingCode
            );
            billingCodeState = inner.state;
            data = { ...data, billingCode: inner.data };
        }
        let nonCfExpenseState;
        {
            const inner = Fields.nonCfExpense.initialize(
                data.nonCfExpense,
                subcontext,
                subparameters.nonCfExpense
            );
            nonCfExpenseState = inner.state;
            data = { ...data, nonCfExpense: inner.data };
        }
        let state = {
            initialParameters: parameters,
            description: descriptionState,
            rate: rateState,
            certifiedForemanRate: certifiedForemanRateState,
            billingCode: billingCodeState,
            nonCfExpense: nonCfExpenseState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext
                    meta={DETAIL_SHEET_TIME_AND_MATERIALS_RATE_META}
                    value={props.data}
                >
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: reduce,
};
export default Widget;
type Widgets = {
    description: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.description>
    >;
    rate: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.rate>
    >;
    certifiedForemanRate: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.certifiedForemanRate>
    >;
    billingCode: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.billingCode>
    >;
    nonCfExpense: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.nonCfExpense>
    >;
};
// END MAGIC -- DO NOT EDIT
