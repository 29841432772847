import Decimal from "decimal.js";
import * as React from "react";
import { Table } from "react-bootstrap";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { sumMap } from "../../../clay/queryFuncs";
import { QuickCacheApi } from "../../../clay/quick-cache";
import {
    RecordContext,
    RecordWidget,
    ValidationError,
    Widget,
    WidgetProps,
    WidgetResult,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { MoneyStatic } from "../../../clay/widgets/money-widget";
import { visitDetailSheetItems } from "./phase-code";
import { DetailSheet, DETAIL_SHEET_META } from "./table";

export type Data = DetailSheet;

function Component(props: Props) {
    const items = React.useMemo(() => {
        const result: {
            source: string;
            name: string;
            cost: Decimal;
        }[] = [];
        visitDetailSheetItems(props.data, (item) => {
            if (item.nonCfExpense) {
                result.push({
                    source: item.source,
                    name: item.name,
                    cost: sumMap(item.subItems, (x) => x.cost),
                });
            }
        });
        return result;
    }, [props.data]);

    return (
        <>
            <Table>
                <thead>
                    <th style={{ width: "10em" }}>Kind</th>
                    <th>Name</th>
                    <th style={{ width: "10em" }}>Amount</th>
                </thead>
                <tbody>
                    {items.map((item) => (
                        <tr>
                            <td style={{ textTransform: "capitalize" }}>
                                {item.source}
                            </td>
                            <td>{item.name}</td>
                            <td>
                                <MoneyStatic value={item.cost} />
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan={2}>Total</th>
                        <th>
                            <MoneyStatic
                                value={sumMap(items, (item) => item.cost)}
                            />
                        </th>
                    </tr>
                </tfoot>
            </Table>
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {};
type ExtraProps = {};
type BaseState = {
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction = unknown;

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    return { state, data };
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: DETAIL_SHEET_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let state = {
            initialParameters: parameters,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={DETAIL_SHEET_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {};
// END MAGIC -- DO NOT EDIT
