import React from "react";
import { Dictionary } from "../../clay/common";
import { propCheck } from "../../clay/propCheck";
import { QuickCacheApi } from "../../clay/quick-cache";
import { DefaultTextWidget } from "../../clay/widgets/DefaultTextWidget";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../clay/widgets/index";
import { MoneyStatic } from "../../clay/widgets/money-widget";
import { TableRow } from "../../clay/widgets/TableRow";
import { StaticTextField } from "../../clay/widgets/TextWidget";
import {
    calcContingencyItemTotal,
    computeContingencyItemRemdalCost,
    ContingencyItem,
    CONTINGENCY_ITEM_META,
    isLumpSumUnitType,
} from "./table";

export type Data = ContingencyItem;

const Fields = {
    groupCode: DefaultTextWidget,
    billingItem: DefaultTextWidget,
};
export type ExtraProps = {
    billingItem: number;
};

function Component(props: Props) {
    const groupCode = isLumpSumUnitType(props.data.type) ? "30" : "20";
    return (
        <TableRow flexSizes>
            <StaticTextField value={props.data.description} />
            <widgets.groupCode defaultText={groupCode} hideStatus />
            <widgets.billingItem
                defaultText={`${groupCode}${props.billingItem}0`}
                hideStatus
            />

            <MoneyStatic value={calcContingencyItemTotal(props.data)} />
            <MoneyStatic value={computeContingencyItemRemdalCost(props.data)} />
        </TableRow>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.groupCode> &
    WidgetContext<typeof Fields.billingItem>;
type BaseState = {
    groupCode: WidgetState<typeof Fields.groupCode>;
    billingItem: WidgetState<typeof Fields.billingItem>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | { type: "GROUP_CODE"; action: WidgetAction<typeof Fields.groupCode> }
    | { type: "BILLING_ITEM"; action: WidgetAction<typeof Fields.billingItem> };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps> & ExtraProps;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(Fields.groupCode, data.groupCode, cache, "groupCode", errors);
    subvalidate(
        Fields.billingItem,
        data.billingItem,
        cache,
        "billingItem",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "GROUP_CODE": {
            const inner = Fields.groupCode.reduce(
                state.groupCode,
                data.groupCode,
                action.action,
                subcontext
            );
            return {
                state: { ...state, groupCode: inner.state },
                data: { ...data, groupCode: inner.data },
            };
        }
        case "BILLING_ITEM": {
            const inner = Fields.billingItem.reduce(
                state.billingItem,
                data.billingItem,
                action.action,
                subcontext
            );
            return {
                state: { ...state, billingItem: inner.state },
                data: { ...data, billingItem: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    groupCode: function (
        props: WidgetExtraProps<typeof Fields.groupCode> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "GROUP_CODE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "groupCode", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.groupCode.component
                state={context.state.groupCode}
                data={context.data.groupCode}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Group Code"}
            />
        );
    },
    billingItem: function (
        props: WidgetExtraProps<typeof Fields.billingItem> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "BILLING_ITEM",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "billingItem", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.billingItem.component
                state={context.state.billingItem}
                data={context.data.billingItem}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Billing Item"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: CONTINGENCY_ITEM_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let groupCodeState;
        {
            const inner = Fields.groupCode.initialize(
                data.groupCode,
                subcontext,
                subparameters.groupCode
            );
            groupCodeState = inner.state;
            data = { ...data, groupCode: inner.data };
        }
        let billingItemState;
        {
            const inner = Fields.billingItem.initialize(
                data.billingItem,
                subcontext,
                subparameters.billingItem
            );
            billingItemState = inner.state;
            data = { ...data, billingItem: inner.data };
        }
        let state = {
            initialParameters: parameters,
            groupCode: groupCodeState,
            billingItem: billingItemState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={CONTINGENCY_ITEM_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    groupCode: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.groupCode>
    >;
    billingItem: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.billingItem>
    >;
};
// END MAGIC -- DO NOT EDIT
