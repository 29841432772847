import dateParse from "date-fns/parseISO";
import Decimal from "decimal.js";
import { Money, Quantity } from "../../../clay/common";
import { Link } from "../../../clay/link";
import { LocalDate } from "../../../clay/LocalDate";
import { RecordMeta } from "../../../clay/meta";
import { daysAgo, isNull, sumMap } from "../../../clay/queryFuncs";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../../clay/version";
import {
  ContactDetail,
  ContactDetailJSON,
  ContactDetailToJSON,
  CONTACT_DETAIL_META,
  JSONToContactDetail,
  repairContactDetailJSON,
} from "../../contact/table";
import {
  ContingencyItem,
  ContingencyItemJSON,
  ContingencyItemToJSON,
  CONTINGENCY_ITEM_META,
  JSONToContingencyItem,
  repairContingencyItemJSON,
  calcContingencyItemCertifiedForemanTotal,
} from "../../contingency/table";
import {
  ApplicationType,
  ApplicationTypeOption,
  ItemType,
} from "../../estimate/types/table";
import {
  JSONToNoteList,
  NoteList,
  NoteListJSON,
  NoteListToJSON,
  NOTE_LIST_META,
  repairNoteListJSON,
} from "../../quotation/notes/table";
import {
  JSONToSourceAreaAllowance,
  repairSourceAreaAllowanceJSON,
  SourceAreaAllowance,
  SourceAreaAllowanceJSON,
  SourceAreaAllowanceToJSON,
  SOURCE_AREA_ALLOWANCE_META,
} from "../../quotation/source-area";
import {
  JSONToRoleWithPercentage,
  Quotation,
  repairRoleWithPercentageJSON,
  RoleWithPercentage,
  RoleWithPercentageJSON,
  RoleWithPercentageToJSON,
  ROLE_WITH_PERCENTAGE_META,
} from "../../quotation/table";
import { User } from "../../user/table";
import { MasterFormatCode } from "../master-format-codes/table";
import {
  JSONToProjectDescriptionDetail,
  ProjectDescriptionDetail,
  ProjectDescriptionDetailJSON,
  ProjectDescriptionDetailToJSON,
  PROJECT_DESCRIPTION_DETAIL_META,
  repairProjectDescriptionDetailJSON,
} from "../projectDescriptionDetail/table";
import {
  JSONToProjectSchedule,
  ProjectSchedule,
  ProjectScheduleJSON,
  ProjectScheduleToJSON,
  PROJECT_SCHEDULE_META,
  repairProjectScheduleJSON,
} from "../schedule";
import { Project } from "../table";
import {
  AnticipatedCrewSize,
  AnticipatedDuration,
  AvailableWorkingDays,
  BillingCode,
  MockupExpectation,
  ParkingType,
  RequiredEquipmentType,
  RequiredNoticeType,
} from "../types/table";

//!Data
export type BudgetLine = {
  nonCfExpense: boolean;
  masterFormatCode: Link<MasterFormatCode>;
  itemType: Link<ItemType>;
  name: string;
  hours: Quantity;
  hourRate: Money;
  materials: Quantity;
  materialsRate: Money;
  originalMaterialsRate: Money | null;
  schedule: Link<ProjectSchedule>;
};

export function scheduleLines(detailSheet: DetailSheet): {
  schedule: Link<ProjectSchedule>;
  amount: Money;
  nonCfExpense: boolean;
}[] {
  return [
    ...detailSheet.options
      .flatMap((option) => option.budget)
      .map((option) => ({
        schedule: option.schedule,
        nonCfExpense: option.nonCfExpense,
        amount: calcBudgetLineTotal(option),
      })),
    ...detailSheet.options
      .flatMap((option) => option.allowances)
      .map((allowance) => ({
        schedule: allowance.schedule,
        nonCfExpense: allowance.nonCfExpense,
        amount: allowance.cost,
      })),
    ...detailSheet.contingencyItems.map((item) => ({
      schedule: item.schedule,
      nonCfExpense: item.nonCfExpense,
      amount: calcContingencyItemCertifiedForemanTotal(item),
    })),
  ];
}

export function calcBudgetHourTotal(line: BudgetLine): Money {
  return line.hours.times(line.hourRate).toDecimalPlaces(2);
}

export function calcBudgetMaterialTotal(line: BudgetLine): Money {
  return line.materials.times(line.materialsRate).toDecimalPlaces(2);
}

export function calcBudgetLineTotal(line: BudgetLine): Money {
  return line.hours
    .times(line.hourRate)
    .toDecimalPlaces(2)
    .plus(line.materials.times(line.materialsRate).toDecimalPlaces(2));
}

//!Data
export type DetailSheetFinishSchedule = {
  id: UUID;
  name: string;
  finishSchedule: string;
  applicationType: Link<ApplicationType>;
  application: Link<ApplicationTypeOption>;
  colour: string;
};

//!Data
export type DetailSheetTimeAndMaterialsRate = {
  id: UUID;
  description: string;
  billingCode: Link<BillingCode>;
  rate: Money;
  certifiedForemanRate: Money;
  nonCfExpense: boolean;
};

//!Data
export type DetailSheetOption = {
  id: UUID;
  name: string;
  description: string;
  finishSchedule: DetailSheetFinishSchedule[];
  allowances: SourceAreaAllowance[];
  budget: BudgetLine[];
};

export function calcDetailSheetOptionOverallTotal(
  option: DetailSheetOption
): Money {
  return sumMap(option.budget, (budget) => calcBudgetLineTotal(budget));
}

export function calcDetailSheetOptionHoursTotal(
  option: DetailSheetOption
): Quantity {
  return sumMap(option.budget, (line) => line.hours);
}

export function calcDetailSheetOptionMaterialsTotal(
  option: DetailSheetOption
): Quantity {
  return sumMap(option.budget, (line) => line.materials);
}

//!Data
export type DetailSheetRequiredEquipment = {
  type: Link<RequiredEquipmentType>;
  notes: string;
  numberOfDrops: Quantity;
  numberOfStages: Quantity;
  buildingHeight: Quantity;
  size: string;
};

//!Data
export type DetailSheet = {
  id: UUID;
  recordVersion: Version;
  project: Link<Project>;
  firstDate: Date | null;
  date: Date | null;
  addedDateTime: Date | null;
  modifiedDateTime: Date | null;
  options: DetailSheetOption[];
  specifierContacts: ContactDetail[];
  contacts: ContactDetail[];
  scopeOfWork: NoteList[];
  contractNotes: NoteList[];
  user: Link<User>;
  quotations: Link<Quotation>[];
  certifiedForeman: Link<User>;
  manager: Link<User>;
  managers: RoleWithPercentage[];
  change: boolean;
  number: Quantity;
  initialized: boolean;
  projectedStartDate: LocalDate | null;
  accessRequirements: string[];
  requiredEquipment: string[];

  schedules: ProjectSchedule[];
  contingencyItems: ContingencyItem[];
  schedulesDividedDescription: boolean;
  description: ProjectDescriptionDetail;

  anticipatedDuration: Link<AnticipatedDuration>;
  availableWorkingDays: Link<AvailableWorkingDays>;
  anticipatedCrewSize: Link<AnticipatedCrewSize>;
  mockupExpectations: Link<MockupExpectation>;
  mockupExpectationsNote: string;
  mockupExpectationsDue: LocalDate | null;
  projectPhases: string[];
  preexistingConditions: string[];
  potentialForUnforeseenWork: string[];

  clientSuccessCommunications: string;
  writtenOrVerbalPromises: string;

  requiredEquipmentList: DetailSheetRequiredEquipment[];
  challengingLocations: string;
  requiredNotices: Link<RequiredNoticeType>[];
  siteStorage: string;
  liftParkingRequired: boolean;
  liftOvernightParkingLocation: string;
  portableToiletRequired: boolean;
  portableToiletProposedLocation: string;
  locationOfOnSiteWashrooms: string;
  parking: Link<ParkingType>;
  parkingNotes: string;

  potentialDelays: string;
  colourApprovalProcess: string;
  permitsRequired: boolean;
  permitsRequiredNote: string;
  potentialBudgetChallenges: string;

  timeAndMaterialRates: DetailSheetTimeAndMaterialsRate[];

  revamped: boolean;

  addedToSiteDocsDate: Date | null;
};

export function resolveDetailSheetSchedules(
  detailSheet: DetailSheet
): DetailSheet {
  if (detailSheet.schedulesDividedDescription) {
    return detailSheet;
  } else {
    return {
      ...detailSheet,
      schedules: detailSheet.schedules.map((projectSchedule) => ({
        ...projectSchedule,
        projectDescription: detailSheet.description,
      })),
      contingencyItems: detailSheet.contingencyItems.map((contingencyItem) => ({
        ...contingencyItem,
        projectDescription: detailSheet.description,
      })),
    };
  }
}

export function calcDetailSheetUngenerated(detailSheet: DetailSheet): boolean {
  return isNull(detailSheet.date) && daysAgo(detailSheet.addedDateTime)!.gt(2);
}

export function calcDetailSheetContractValue(detailSheet: DetailSheet): Money {
  return sumMap(
    detailSheet.schedules,
    (schedule) => schedule.certifiedForemanContractAmount
  );
}

// BEGIN MAGIC -- DO NOT EDIT
export type BudgetLineJSON = {
  nonCfExpense: boolean;
  masterFormatCode: string | null;
  itemType: string | null;
  name: string;
  hours: string;
  hourRate: string;
  materials: string;
  materialsRate: string;
  originalMaterialsRate: string | null;
  schedule: string | null;
};

export function JSONToBudgetLine(json: BudgetLineJSON): BudgetLine {
  return {
    nonCfExpense: json.nonCfExpense,
    masterFormatCode: json.masterFormatCode,
    itemType: json.itemType,
    name: json.name,
    hours: new Decimal(json.hours),
    hourRate: new Decimal(json.hourRate),
    materials: new Decimal(json.materials),
    materialsRate: new Decimal(json.materialsRate),
    originalMaterialsRate:
      json.originalMaterialsRate !== null
        ? new Decimal(json.originalMaterialsRate)
        : null,
    schedule: json.schedule,
  };
}
export type BudgetLineBrokenJSON = {
  nonCfExpense?: boolean;
  masterFormatCode?: string | null;
  itemType?: string | null;
  name?: string;
  hours?: string;
  hourRate?: string;
  materials?: string;
  materialsRate?: string;
  originalMaterialsRate?: string | null;
  schedule?: string | null;
};

export function newBudgetLine(): BudgetLine {
  return JSONToBudgetLine(repairBudgetLineJSON(undefined));
}
export function repairBudgetLineJSON(
  json: BudgetLineBrokenJSON | undefined
): BudgetLineJSON {
  if (json) {
    return {
      nonCfExpense: json.nonCfExpense || false,
      masterFormatCode: json.masterFormatCode || null,
      itemType: json.itemType || null,
      name: json.name || "",
      hours: json.hours || "0",
      hourRate: json.hourRate || "0",
      materials: json.materials || "0",
      materialsRate: json.materialsRate || "0",
      originalMaterialsRate: json.originalMaterialsRate || null,
      schedule: json.schedule || null,
    };
  } else {
    return {
      nonCfExpense: undefined || false,
      masterFormatCode: undefined || null,
      itemType: undefined || null,
      name: undefined || "",
      hours: undefined || "0",
      hourRate: undefined || "0",
      materials: undefined || "0",
      materialsRate: undefined || "0",
      originalMaterialsRate: undefined || null,
      schedule: undefined || null,
    };
  }
}

export function BudgetLineToJSON(value: BudgetLine): BudgetLineJSON {
  return {
    nonCfExpense: value.nonCfExpense,
    masterFormatCode: value.masterFormatCode,
    itemType: value.itemType,
    name: value.name,
    hours: value.hours.toString(),
    hourRate: value.hourRate.toString(),
    materials: value.materials.toString(),
    materialsRate: value.materialsRate.toString(),
    originalMaterialsRate:
      value.originalMaterialsRate !== null
        ? value.originalMaterialsRate.toString()
        : null,
    schedule: value.schedule,
  };
}

export const BUDGET_LINE_META: RecordMeta<
  BudgetLine,
  BudgetLineJSON,
  BudgetLineBrokenJSON
> & { name: "BudgetLine" } = {
  name: "BudgetLine",
  type: "record",
  repair: repairBudgetLineJSON,
  toJSON: BudgetLineToJSON,
  fromJSON: JSONToBudgetLine,
  fields: {
    nonCfExpense: { type: "boolean" },
    masterFormatCode: { type: "uuid", linkTo: "MasterFormatCode" },
    itemType: { type: "uuid", linkTo: "ItemType" },
    name: { type: "string" },
    hours: { type: "quantity" },
    hourRate: { type: "money" },
    materials: { type: "quantity" },
    materialsRate: { type: "money" },
    originalMaterialsRate: { type: "money?" },
    schedule: { type: "uuid", linkTo: "ProjectSchedule" },
  },
  userFacingKey: "name",
  functions: {
    total: {
      fn: calcBudgetLineTotal,
      parameterTypes: () => [BUDGET_LINE_META],
      returnType: { type: "money" },
    },
  },
  segments: {},
};

export type DetailSheetFinishScheduleJSON = {
  id: string;
  name: string;
  finishSchedule: string;
  applicationType: string | null;
  application: string | null;
  colour: string;
};

export function JSONToDetailSheetFinishSchedule(
  json: DetailSheetFinishScheduleJSON
): DetailSheetFinishSchedule {
  return {
    id: { uuid: json.id },
    name: json.name,
    finishSchedule: json.finishSchedule,
    applicationType: json.applicationType,
    application: json.application,
    colour: json.colour,
  };
}
export type DetailSheetFinishScheduleBrokenJSON = {
  id?: string;
  name?: string;
  finishSchedule?: string;
  applicationType?: string | null;
  application?: string | null;
  colour?: string;
};

export function newDetailSheetFinishSchedule(): DetailSheetFinishSchedule {
  return JSONToDetailSheetFinishSchedule(
    repairDetailSheetFinishScheduleJSON(undefined)
  );
}
export function repairDetailSheetFinishScheduleJSON(
  json: DetailSheetFinishScheduleBrokenJSON | undefined
): DetailSheetFinishScheduleJSON {
  if (json) {
    return {
      id: json.id || genUUID(),
      name: json.name || "",
      finishSchedule: json.finishSchedule || "",
      applicationType: json.applicationType || null,
      application: json.application || null,
      colour: json.colour || "",
    };
  } else {
    return {
      id: undefined || genUUID(),
      name: undefined || "",
      finishSchedule: undefined || "",
      applicationType: undefined || null,
      application: undefined || null,
      colour: undefined || "",
    };
  }
}

export function DetailSheetFinishScheduleToJSON(
  value: DetailSheetFinishSchedule
): DetailSheetFinishScheduleJSON {
  return {
    id: value.id.uuid,
    name: value.name,
    finishSchedule: value.finishSchedule,
    applicationType: value.applicationType,
    application: value.application,
    colour: value.colour,
  };
}

export const DETAIL_SHEET_FINISH_SCHEDULE_META: RecordMeta<
  DetailSheetFinishSchedule,
  DetailSheetFinishScheduleJSON,
  DetailSheetFinishScheduleBrokenJSON
> & { name: "DetailSheetFinishSchedule" } = {
  name: "DetailSheetFinishSchedule",
  type: "record",
  repair: repairDetailSheetFinishScheduleJSON,
  toJSON: DetailSheetFinishScheduleToJSON,
  fromJSON: JSONToDetailSheetFinishSchedule,
  fields: {
    id: { type: "uuid" },
    name: { type: "string" },
    finishSchedule: { type: "string" },
    applicationType: { type: "uuid", linkTo: "ApplicationType" },
    application: { type: "uuid", linkTo: "ApplicationTypeOption" },
    colour: { type: "string" },
  },
  userFacingKey: "name",
  functions: {},
  segments: {},
};

export type DetailSheetTimeAndMaterialsRateJSON = {
  id: string;
  description: string;
  billingCode: string | null;
  rate: string;
  certifiedForemanRate: string;
  nonCfExpense: boolean;
};

export function JSONToDetailSheetTimeAndMaterialsRate(
  json: DetailSheetTimeAndMaterialsRateJSON
): DetailSheetTimeAndMaterialsRate {
  return {
    id: { uuid: json.id },
    description: json.description,
    billingCode: json.billingCode,
    rate: new Decimal(json.rate),
    certifiedForemanRate: new Decimal(json.certifiedForemanRate),
    nonCfExpense: json.nonCfExpense,
  };
}
export type DetailSheetTimeAndMaterialsRateBrokenJSON = {
  id?: string;
  description?: string;
  billingCode?: string | null;
  rate?: string;
  certifiedForemanRate?: string;
  nonCfExpense?: boolean;
};

export function newDetailSheetTimeAndMaterialsRate(): DetailSheetTimeAndMaterialsRate {
  return JSONToDetailSheetTimeAndMaterialsRate(
    repairDetailSheetTimeAndMaterialsRateJSON(undefined)
  );
}
export function repairDetailSheetTimeAndMaterialsRateJSON(
  json: DetailSheetTimeAndMaterialsRateBrokenJSON | undefined
): DetailSheetTimeAndMaterialsRateJSON {
  if (json) {
    return {
      id: json.id || genUUID(),
      description: json.description || "",
      billingCode: json.billingCode || null,
      rate: json.rate || "0",
      certifiedForemanRate: json.certifiedForemanRate || "0",
      nonCfExpense: json.nonCfExpense || false,
    };
  } else {
    return {
      id: undefined || genUUID(),
      description: undefined || "",
      billingCode: undefined || null,
      rate: undefined || "0",
      certifiedForemanRate: undefined || "0",
      nonCfExpense: undefined || false,
    };
  }
}

export function DetailSheetTimeAndMaterialsRateToJSON(
  value: DetailSheetTimeAndMaterialsRate
): DetailSheetTimeAndMaterialsRateJSON {
  return {
    id: value.id.uuid,
    description: value.description,
    billingCode: value.billingCode,
    rate: value.rate.toString(),
    certifiedForemanRate: value.certifiedForemanRate.toString(),
    nonCfExpense: value.nonCfExpense,
  };
}

export const DETAIL_SHEET_TIME_AND_MATERIALS_RATE_META: RecordMeta<
  DetailSheetTimeAndMaterialsRate,
  DetailSheetTimeAndMaterialsRateJSON,
  DetailSheetTimeAndMaterialsRateBrokenJSON
> & { name: "DetailSheetTimeAndMaterialsRate" } = {
  name: "DetailSheetTimeAndMaterialsRate",
  type: "record",
  repair: repairDetailSheetTimeAndMaterialsRateJSON,
  toJSON: DetailSheetTimeAndMaterialsRateToJSON,
  fromJSON: JSONToDetailSheetTimeAndMaterialsRate,
  fields: {
    id: { type: "uuid" },
    description: { type: "string" },
    billingCode: { type: "uuid", linkTo: "BillingCode" },
    rate: { type: "money" },
    certifiedForemanRate: { type: "money" },
    nonCfExpense: { type: "boolean" },
  },
  userFacingKey: null,
  functions: {},
  segments: {},
};

export type DetailSheetOptionJSON = {
  id: string;
  name: string;
  description: string;
  finishSchedule: DetailSheetFinishScheduleJSON[];
  allowances: SourceAreaAllowanceJSON[];
  budget: BudgetLineJSON[];
};

export function JSONToDetailSheetOption(
  json: DetailSheetOptionJSON
): DetailSheetOption {
  return {
    id: { uuid: json.id },
    name: json.name,
    description: json.description,
    finishSchedule: json.finishSchedule.map((inner) =>
      JSONToDetailSheetFinishSchedule(inner)
    ),
    allowances: json.allowances.map((inner) =>
      JSONToSourceAreaAllowance(inner)
    ),
    budget: json.budget.map((inner) => JSONToBudgetLine(inner)),
  };
}
export type DetailSheetOptionBrokenJSON = {
  id?: string;
  name?: string;
  description?: string;
  finishSchedule?: DetailSheetFinishScheduleJSON[];
  allowances?: SourceAreaAllowanceJSON[];
  budget?: BudgetLineJSON[];
};

export function newDetailSheetOption(): DetailSheetOption {
  return JSONToDetailSheetOption(repairDetailSheetOptionJSON(undefined));
}
export function repairDetailSheetOptionJSON(
  json: DetailSheetOptionBrokenJSON | undefined
): DetailSheetOptionJSON {
  if (json) {
    return {
      id: json.id || genUUID(),
      name: json.name || "",
      description: json.description || "",
      finishSchedule: (json.finishSchedule || []).map((inner) =>
        repairDetailSheetFinishScheduleJSON(inner)
      ),
      allowances: (json.allowances || []).map((inner) =>
        repairSourceAreaAllowanceJSON(inner)
      ),
      budget: (json.budget || []).map((inner) => repairBudgetLineJSON(inner)),
    };
  } else {
    return {
      id: undefined || genUUID(),
      name: undefined || "",
      description: undefined || "",
      finishSchedule: (undefined || []).map((inner) =>
        repairDetailSheetFinishScheduleJSON(inner)
      ),
      allowances: (undefined || []).map((inner) =>
        repairSourceAreaAllowanceJSON(inner)
      ),
      budget: (undefined || []).map((inner) => repairBudgetLineJSON(inner)),
    };
  }
}

export function DetailSheetOptionToJSON(
  value: DetailSheetOption
): DetailSheetOptionJSON {
  return {
    id: value.id.uuid,
    name: value.name,
    description: value.description,
    finishSchedule: value.finishSchedule.map((inner) =>
      DetailSheetFinishScheduleToJSON(inner)
    ),
    allowances: value.allowances.map((inner) =>
      SourceAreaAllowanceToJSON(inner)
    ),
    budget: value.budget.map((inner) => BudgetLineToJSON(inner)),
  };
}

export const DETAIL_SHEET_OPTION_META: RecordMeta<
  DetailSheetOption,
  DetailSheetOptionJSON,
  DetailSheetOptionBrokenJSON
> & { name: "DetailSheetOption" } = {
  name: "DetailSheetOption",
  type: "record",
  repair: repairDetailSheetOptionJSON,
  toJSON: DetailSheetOptionToJSON,
  fromJSON: JSONToDetailSheetOption,
  fields: {
    id: { type: "uuid" },
    name: { type: "string" },
    description: { type: "string" },
    finishSchedule: {
      type: "array",
      items: DETAIL_SHEET_FINISH_SCHEDULE_META,
    },
    allowances: { type: "array", items: SOURCE_AREA_ALLOWANCE_META },
    budget: { type: "array", items: BUDGET_LINE_META },
  },
  userFacingKey: "name",
  functions: {
    overallTotal: {
      fn: calcDetailSheetOptionOverallTotal,
      parameterTypes: () => [DETAIL_SHEET_OPTION_META],
      returnType: { type: "money" },
    },
    hoursTotal: {
      fn: calcDetailSheetOptionHoursTotal,
      parameterTypes: () => [DETAIL_SHEET_OPTION_META],
      returnType: { type: "quantity" },
    },
    materialsTotal: {
      fn: calcDetailSheetOptionMaterialsTotal,
      parameterTypes: () => [DETAIL_SHEET_OPTION_META],
      returnType: { type: "quantity" },
    },
  },
  segments: {},
};

export type DetailSheetRequiredEquipmentJSON = {
  type: string | null;
  notes: string;
  numberOfDrops: string;
  numberOfStages: string;
  buildingHeight: string;
  size: string;
};

export function JSONToDetailSheetRequiredEquipment(
  json: DetailSheetRequiredEquipmentJSON
): DetailSheetRequiredEquipment {
  return {
    type: json.type,
    notes: json.notes,
    numberOfDrops: new Decimal(json.numberOfDrops),
    numberOfStages: new Decimal(json.numberOfStages),
    buildingHeight: new Decimal(json.buildingHeight),
    size: json.size,
  };
}
export type DetailSheetRequiredEquipmentBrokenJSON = {
  type?: string | null;
  notes?: string;
  numberOfDrops?: string;
  numberOfStages?: string;
  buildingHeight?: string;
  size?: string;
};

export function newDetailSheetRequiredEquipment(): DetailSheetRequiredEquipment {
  return JSONToDetailSheetRequiredEquipment(
    repairDetailSheetRequiredEquipmentJSON(undefined)
  );
}
export function repairDetailSheetRequiredEquipmentJSON(
  json: DetailSheetRequiredEquipmentBrokenJSON | undefined
): DetailSheetRequiredEquipmentJSON {
  if (json) {
    return {
      type: json.type || null,
      notes: json.notes || "",
      numberOfDrops: json.numberOfDrops || "0",
      numberOfStages: json.numberOfStages || "0",
      buildingHeight: json.buildingHeight || "0",
      size: json.size || "",
    };
  } else {
    return {
      type: undefined || null,
      notes: undefined || "",
      numberOfDrops: undefined || "0",
      numberOfStages: undefined || "0",
      buildingHeight: undefined || "0",
      size: undefined || "",
    };
  }
}

export function DetailSheetRequiredEquipmentToJSON(
  value: DetailSheetRequiredEquipment
): DetailSheetRequiredEquipmentJSON {
  return {
    type: value.type,
    notes: value.notes,
    numberOfDrops: value.numberOfDrops.toString(),
    numberOfStages: value.numberOfStages.toString(),
    buildingHeight: value.buildingHeight.toString(),
    size: value.size,
  };
}

export const DETAIL_SHEET_REQUIRED_EQUIPMENT_META: RecordMeta<
  DetailSheetRequiredEquipment,
  DetailSheetRequiredEquipmentJSON,
  DetailSheetRequiredEquipmentBrokenJSON
> & { name: "DetailSheetRequiredEquipment" } = {
  name: "DetailSheetRequiredEquipment",
  type: "record",
  repair: repairDetailSheetRequiredEquipmentJSON,
  toJSON: DetailSheetRequiredEquipmentToJSON,
  fromJSON: JSONToDetailSheetRequiredEquipment,
  fields: {
    type: { type: "uuid", linkTo: "RequiredEquipmentType" },
    notes: { type: "string" },
    numberOfDrops: { type: "quantity" },
    numberOfStages: { type: "quantity" },
    buildingHeight: { type: "quantity" },
    size: { type: "string" },
  },
  userFacingKey: null,
  functions: {},
  segments: {},
};

export type DetailSheetJSON = {
  id: string;
  recordVersion: number | null;
  project: string | null;
  firstDate: string | null;
  date: string | null;
  addedDateTime: string | null;
  modifiedDateTime: string | null;
  options: DetailSheetOptionJSON[];
  specifierContacts: ContactDetailJSON[];
  contacts: ContactDetailJSON[];
  scopeOfWork: NoteListJSON[];
  contractNotes: NoteListJSON[];
  user: string | null;
  quotations: (string | null)[];
  certifiedForeman: string | null;
  manager: string | null;
  managers: RoleWithPercentageJSON[];
  change: boolean;
  number: string;
  initialized: boolean;
  projectedStartDate: string | null;
  accessRequirements: string[];
  requiredEquipment: string[];
  schedules: ProjectScheduleJSON[];
  contingencyItems: ContingencyItemJSON[];
  schedulesDividedDescription: boolean;
  description: ProjectDescriptionDetailJSON;
  anticipatedDuration: string | null;
  availableWorkingDays: string | null;
  anticipatedCrewSize: string | null;
  mockupExpectations: string | null;
  mockupExpectationsNote: string;
  mockupExpectationsDue: string | null;
  projectPhases: string[];
  preexistingConditions: string[];
  potentialForUnforeseenWork: string[];
  clientSuccessCommunications: string;
  writtenOrVerbalPromises: string;
  requiredEquipmentList: DetailSheetRequiredEquipmentJSON[];
  challengingLocations: string;
  requiredNotices: (string | null)[];
  siteStorage: string;
  liftParkingRequired: boolean;
  liftOvernightParkingLocation: string;
  portableToiletRequired: boolean;
  portableToiletProposedLocation: string;
  locationOfOnSiteWashrooms: string;
  parking: string | null;
  parkingNotes: string;
  potentialDelays: string;
  colourApprovalProcess: string;
  permitsRequired: boolean;
  permitsRequiredNote: string;
  potentialBudgetChallenges: string;
  timeAndMaterialRates: DetailSheetTimeAndMaterialsRateJSON[];
  revamped: boolean;
  addedToSiteDocsDate: string | null;
};

export function JSONToDetailSheet(json: DetailSheetJSON): DetailSheet {
  return {
    id: { uuid: json.id },
    recordVersion: { version: json.recordVersion },
    project: json.project,
    firstDate: json.firstDate !== null ? dateParse(json.firstDate) : null,
    date: json.date !== null ? dateParse(json.date) : null,
    addedDateTime:
      json.addedDateTime !== null ? dateParse(json.addedDateTime) : null,
    modifiedDateTime:
      json.modifiedDateTime !== null ? dateParse(json.modifiedDateTime) : null,
    options: json.options.map((inner) => JSONToDetailSheetOption(inner)),
    specifierContacts: json.specifierContacts.map((inner) =>
      JSONToContactDetail(inner)
    ),
    contacts: json.contacts.map((inner) => JSONToContactDetail(inner)),
    scopeOfWork: json.scopeOfWork.map((inner) => JSONToNoteList(inner)),
    contractNotes: json.contractNotes.map((inner) => JSONToNoteList(inner)),
    user: json.user,
    quotations: json.quotations.map((inner) => inner),
    certifiedForeman: json.certifiedForeman,
    manager: json.manager,
    managers: json.managers.map((inner) => JSONToRoleWithPercentage(inner)),
    change: json.change,
    number: new Decimal(json.number),
    initialized: json.initialized,
    projectedStartDate:
      json.projectedStartDate !== null
        ? LocalDate.parse(json.projectedStartDate)
        : null,
    accessRequirements: json.accessRequirements.map((inner) => inner),
    requiredEquipment: json.requiredEquipment.map((inner) => inner),
    schedules: json.schedules.map((inner) => JSONToProjectSchedule(inner)),
    contingencyItems: json.contingencyItems.map((inner) =>
      JSONToContingencyItem(inner)
    ),
    schedulesDividedDescription: json.schedulesDividedDescription,
    description: JSONToProjectDescriptionDetail(json.description),
    anticipatedDuration: json.anticipatedDuration,
    availableWorkingDays: json.availableWorkingDays,
    anticipatedCrewSize: json.anticipatedCrewSize,
    mockupExpectations: json.mockupExpectations,
    mockupExpectationsNote: json.mockupExpectationsNote,
    mockupExpectationsDue:
      json.mockupExpectationsDue !== null
        ? LocalDate.parse(json.mockupExpectationsDue)
        : null,
    projectPhases: json.projectPhases.map((inner) => inner),
    preexistingConditions: json.preexistingConditions.map((inner) => inner),
    potentialForUnforeseenWork: json.potentialForUnforeseenWork.map(
      (inner) => inner
    ),
    clientSuccessCommunications: json.clientSuccessCommunications,
    writtenOrVerbalPromises: json.writtenOrVerbalPromises,
    requiredEquipmentList: json.requiredEquipmentList.map((inner) =>
      JSONToDetailSheetRequiredEquipment(inner)
    ),
    challengingLocations: json.challengingLocations,
    requiredNotices: json.requiredNotices.map((inner) => inner),
    siteStorage: json.siteStorage,
    liftParkingRequired: json.liftParkingRequired,
    liftOvernightParkingLocation: json.liftOvernightParkingLocation,
    portableToiletRequired: json.portableToiletRequired,
    portableToiletProposedLocation: json.portableToiletProposedLocation,
    locationOfOnSiteWashrooms: json.locationOfOnSiteWashrooms,
    parking: json.parking,
    parkingNotes: json.parkingNotes,
    potentialDelays: json.potentialDelays,
    colourApprovalProcess: json.colourApprovalProcess,
    permitsRequired: json.permitsRequired,
    permitsRequiredNote: json.permitsRequiredNote,
    potentialBudgetChallenges: json.potentialBudgetChallenges,
    timeAndMaterialRates: json.timeAndMaterialRates.map((inner) =>
      JSONToDetailSheetTimeAndMaterialsRate(inner)
    ),
    revamped: json.revamped,
    addedToSiteDocsDate:
      json.addedToSiteDocsDate !== null
        ? dateParse(json.addedToSiteDocsDate)
        : null,
  };
}
export type DetailSheetBrokenJSON = {
  id?: string;
  recordVersion?: number | null;
  project?: string | null;
  firstDate?: string | null;
  date?: string | null;
  addedDateTime?: string | null;
  modifiedDateTime?: string | null;
  options?: DetailSheetOptionJSON[];
  specifierContacts?: ContactDetailJSON[];
  contacts?: ContactDetailJSON[];
  scopeOfWork?: NoteListJSON[];
  contractNotes?: NoteListJSON[];
  user?: string | null;
  quotations?: (string | null)[];
  certifiedForeman?: string | null;
  manager?: string | null;
  managers?: RoleWithPercentageJSON[];
  change?: boolean;
  number?: string;
  initialized?: boolean;
  projectedStartDate?: string | null;
  accessRequirements?: string[];
  requiredEquipment?: string[];
  schedules?: ProjectScheduleJSON[];
  contingencyItems?: ContingencyItemJSON[];
  schedulesDividedDescription?: boolean;
  description?: ProjectDescriptionDetailJSON;
  anticipatedDuration?: string | null;
  availableWorkingDays?: string | null;
  anticipatedCrewSize?: string | null;
  mockupExpectations?: string | null;
  mockupExpectationsNote?: string;
  mockupExpectationsDue?: string | null;
  projectPhases?: string[];
  preexistingConditions?: string[];
  potentialForUnforeseenWork?: string[];
  clientSuccessCommunications?: string;
  writtenOrVerbalPromises?: string;
  requiredEquipmentList?: DetailSheetRequiredEquipmentJSON[];
  challengingLocations?: string;
  requiredNotices?: (string | null)[];
  siteStorage?: string;
  liftParkingRequired?: boolean;
  liftOvernightParkingLocation?: string;
  portableToiletRequired?: boolean;
  portableToiletProposedLocation?: string;
  locationOfOnSiteWashrooms?: string;
  parking?: string | null;
  parkingNotes?: string;
  potentialDelays?: string;
  colourApprovalProcess?: string;
  permitsRequired?: boolean;
  permitsRequiredNote?: string;
  potentialBudgetChallenges?: string;
  timeAndMaterialRates?: DetailSheetTimeAndMaterialsRateJSON[];
  revamped?: boolean;
  addedToSiteDocsDate?: string | null;
};

export function newDetailSheet(): DetailSheet {
  return JSONToDetailSheet(repairDetailSheetJSON(undefined));
}
export function repairDetailSheetJSON(
  json: DetailSheetBrokenJSON | undefined
): DetailSheetJSON {
  if (json) {
    return {
      id: json.id || genUUID(),
      recordVersion:
        json.recordVersion === undefined ? null : json.recordVersion,
      project: json.project || null,
      firstDate: json.firstDate
        ? new Date(json.firstDate!).toISOString()
        : null,
      date: json.date ? new Date(json.date!).toISOString() : null,
      addedDateTime: json.addedDateTime
        ? new Date(json.addedDateTime!).toISOString()
        : null,
      modifiedDateTime: json.modifiedDateTime
        ? new Date(json.modifiedDateTime!).toISOString()
        : null,
      options: (json.options || []).map((inner) =>
        repairDetailSheetOptionJSON(inner)
      ),
      specifierContacts: (json.specifierContacts || []).map((inner) =>
        repairContactDetailJSON(inner)
      ),
      contacts: (json.contacts || []).map((inner) =>
        repairContactDetailJSON(inner)
      ),
      scopeOfWork: (json.scopeOfWork || []).map((inner) =>
        repairNoteListJSON(inner)
      ),
      contractNotes: (json.contractNotes || []).map((inner) =>
        repairNoteListJSON(inner)
      ),
      user: json.user || null,
      quotations: (json.quotations || []).map((inner) => inner || null),
      certifiedForeman: json.certifiedForeman || null,
      manager: json.manager || null,
      managers: (json.managers || []).map((inner) =>
        repairRoleWithPercentageJSON(inner)
      ),
      change: json.change || false,
      number: json.number || "0",
      initialized: json.initialized || false,
      projectedStartDate: json.projectedStartDate || null,
      accessRequirements: (json.accessRequirements || []).map(
        (inner) => inner || ""
      ),
      requiredEquipment: (json.requiredEquipment || []).map(
        (inner) => inner || ""
      ),
      schedules: (json.schedules || []).map((inner) =>
        repairProjectScheduleJSON(inner)
      ),
      contingencyItems: (json.contingencyItems || []).map((inner) =>
        repairContingencyItemJSON(inner)
      ),
      schedulesDividedDescription: json.schedulesDividedDescription || false,
      description: repairProjectDescriptionDetailJSON(json.description),
      anticipatedDuration: json.anticipatedDuration || null,
      availableWorkingDays: json.availableWorkingDays || null,
      anticipatedCrewSize: json.anticipatedCrewSize || null,
      mockupExpectations: json.mockupExpectations || null,
      mockupExpectationsNote: json.mockupExpectationsNote || "",
      mockupExpectationsDue: json.mockupExpectationsDue || null,
      projectPhases: (json.projectPhases || []).map((inner) => inner || ""),
      preexistingConditions: (json.preexistingConditions || []).map(
        (inner) => inner || ""
      ),
      potentialForUnforeseenWork: (json.potentialForUnforeseenWork || []).map(
        (inner) => inner || ""
      ),
      clientSuccessCommunications: json.clientSuccessCommunications || "",
      writtenOrVerbalPromises: json.writtenOrVerbalPromises || "",
      requiredEquipmentList: (json.requiredEquipmentList || []).map((inner) =>
        repairDetailSheetRequiredEquipmentJSON(inner)
      ),
      challengingLocations: json.challengingLocations || "",
      requiredNotices: (json.requiredNotices || []).map(
        (inner) => inner || null
      ),
      siteStorage: json.siteStorage || "",
      liftParkingRequired: json.liftParkingRequired || false,
      liftOvernightParkingLocation: json.liftOvernightParkingLocation || "",
      portableToiletRequired: json.portableToiletRequired || false,
      portableToiletProposedLocation: json.portableToiletProposedLocation || "",
      locationOfOnSiteWashrooms: json.locationOfOnSiteWashrooms || "",
      parking: json.parking || null,
      parkingNotes: json.parkingNotes || "",
      potentialDelays: json.potentialDelays || "",
      colourApprovalProcess: json.colourApprovalProcess || "",
      permitsRequired: json.permitsRequired || false,
      permitsRequiredNote: json.permitsRequiredNote || "",
      potentialBudgetChallenges: json.potentialBudgetChallenges || "",
      timeAndMaterialRates: (json.timeAndMaterialRates || []).map((inner) =>
        repairDetailSheetTimeAndMaterialsRateJSON(inner)
      ),
      revamped: json.revamped || false,
      addedToSiteDocsDate: json.addedToSiteDocsDate
        ? new Date(json.addedToSiteDocsDate!).toISOString()
        : null,
    };
  } else {
    return {
      id: undefined || genUUID(),
      recordVersion: null,
      project: undefined || null,
      firstDate: undefined ? new Date(undefined!).toISOString() : null,
      date: undefined ? new Date(undefined!).toISOString() : null,
      addedDateTime: undefined ? new Date(undefined!).toISOString() : null,
      modifiedDateTime: undefined ? new Date(undefined!).toISOString() : null,
      options: (undefined || []).map((inner) =>
        repairDetailSheetOptionJSON(inner)
      ),
      specifierContacts: (undefined || []).map((inner) =>
        repairContactDetailJSON(inner)
      ),
      contacts: (undefined || []).map((inner) =>
        repairContactDetailJSON(inner)
      ),
      scopeOfWork: (undefined || []).map((inner) => repairNoteListJSON(inner)),
      contractNotes: (undefined || []).map((inner) =>
        repairNoteListJSON(inner)
      ),
      user: undefined || null,
      quotations: (undefined || []).map((inner) => inner || null),
      certifiedForeman: undefined || null,
      manager: undefined || null,
      managers: (undefined || []).map((inner) =>
        repairRoleWithPercentageJSON(inner)
      ),
      change: undefined || false,
      number: undefined || "0",
      initialized: undefined || false,
      projectedStartDate: undefined || null,
      accessRequirements: (undefined || []).map((inner) => inner || ""),
      requiredEquipment: (undefined || []).map((inner) => inner || ""),
      schedules: (undefined || []).map((inner) =>
        repairProjectScheduleJSON(inner)
      ),
      contingencyItems: (undefined || []).map((inner) =>
        repairContingencyItemJSON(inner)
      ),
      schedulesDividedDescription: undefined || false,
      description: repairProjectDescriptionDetailJSON(undefined),
      anticipatedDuration: undefined || null,
      availableWorkingDays: undefined || null,
      anticipatedCrewSize: undefined || null,
      mockupExpectations: undefined || null,
      mockupExpectationsNote: undefined || "",
      mockupExpectationsDue: undefined || null,
      projectPhases: (undefined || []).map((inner) => inner || ""),
      preexistingConditions: (undefined || []).map((inner) => inner || ""),
      potentialForUnforeseenWork: (undefined || []).map((inner) => inner || ""),
      clientSuccessCommunications: undefined || "",
      writtenOrVerbalPromises: undefined || "",
      requiredEquipmentList: (undefined || []).map((inner) =>
        repairDetailSheetRequiredEquipmentJSON(inner)
      ),
      challengingLocations: undefined || "",
      requiredNotices: (undefined || []).map((inner) => inner || null),
      siteStorage: undefined || "",
      liftParkingRequired: undefined || false,
      liftOvernightParkingLocation: undefined || "",
      portableToiletRequired: undefined || false,
      portableToiletProposedLocation: undefined || "",
      locationOfOnSiteWashrooms: undefined || "",
      parking: undefined || null,
      parkingNotes: undefined || "",
      potentialDelays: undefined || "",
      colourApprovalProcess: undefined || "",
      permitsRequired: undefined || false,
      permitsRequiredNote: undefined || "",
      potentialBudgetChallenges: undefined || "",
      timeAndMaterialRates: (undefined || []).map((inner) =>
        repairDetailSheetTimeAndMaterialsRateJSON(inner)
      ),
      revamped: undefined || false,
      addedToSiteDocsDate: undefined
        ? new Date(undefined!).toISOString()
        : null,
    };
  }
}

export function DetailSheetToJSON(value: DetailSheet): DetailSheetJSON {
  return {
    id: value.id.uuid,
    recordVersion: value.recordVersion.version,
    project: value.project,
    firstDate: value.firstDate !== null ? value.firstDate.toISOString() : null,
    date: value.date !== null ? value.date.toISOString() : null,
    addedDateTime:
      value.addedDateTime !== null ? value.addedDateTime.toISOString() : null,
    modifiedDateTime:
      value.modifiedDateTime !== null
        ? value.modifiedDateTime.toISOString()
        : null,
    options: value.options.map((inner) => DetailSheetOptionToJSON(inner)),
    specifierContacts: value.specifierContacts.map((inner) =>
      ContactDetailToJSON(inner)
    ),
    contacts: value.contacts.map((inner) => ContactDetailToJSON(inner)),
    scopeOfWork: value.scopeOfWork.map((inner) => NoteListToJSON(inner)),
    contractNotes: value.contractNotes.map((inner) => NoteListToJSON(inner)),
    user: value.user,
    quotations: value.quotations.map((inner) => inner),
    certifiedForeman: value.certifiedForeman,
    manager: value.manager,
    managers: value.managers.map((inner) => RoleWithPercentageToJSON(inner)),
    change: value.change,
    number: value.number.toString(),
    initialized: value.initialized,
    projectedStartDate:
      value.projectedStartDate !== null
        ? value.projectedStartDate.toString()
        : null,
    accessRequirements: value.accessRequirements.map((inner) => inner),
    requiredEquipment: value.requiredEquipment.map((inner) => inner),
    schedules: value.schedules.map((inner) => ProjectScheduleToJSON(inner)),
    contingencyItems: value.contingencyItems.map((inner) =>
      ContingencyItemToJSON(inner)
    ),
    schedulesDividedDescription: value.schedulesDividedDescription,
    description: ProjectDescriptionDetailToJSON(value.description),
    anticipatedDuration: value.anticipatedDuration,
    availableWorkingDays: value.availableWorkingDays,
    anticipatedCrewSize: value.anticipatedCrewSize,
    mockupExpectations: value.mockupExpectations,
    mockupExpectationsNote: value.mockupExpectationsNote,
    mockupExpectationsDue:
      value.mockupExpectationsDue !== null
        ? value.mockupExpectationsDue.toString()
        : null,
    projectPhases: value.projectPhases.map((inner) => inner),
    preexistingConditions: value.preexistingConditions.map((inner) => inner),
    potentialForUnforeseenWork: value.potentialForUnforeseenWork.map(
      (inner) => inner
    ),
    clientSuccessCommunications: value.clientSuccessCommunications,
    writtenOrVerbalPromises: value.writtenOrVerbalPromises,
    requiredEquipmentList: value.requiredEquipmentList.map((inner) =>
      DetailSheetRequiredEquipmentToJSON(inner)
    ),
    challengingLocations: value.challengingLocations,
    requiredNotices: value.requiredNotices.map((inner) => inner),
    siteStorage: value.siteStorage,
    liftParkingRequired: value.liftParkingRequired,
    liftOvernightParkingLocation: value.liftOvernightParkingLocation,
    portableToiletRequired: value.portableToiletRequired,
    portableToiletProposedLocation: value.portableToiletProposedLocation,
    locationOfOnSiteWashrooms: value.locationOfOnSiteWashrooms,
    parking: value.parking,
    parkingNotes: value.parkingNotes,
    potentialDelays: value.potentialDelays,
    colourApprovalProcess: value.colourApprovalProcess,
    permitsRequired: value.permitsRequired,
    permitsRequiredNote: value.permitsRequiredNote,
    potentialBudgetChallenges: value.potentialBudgetChallenges,
    timeAndMaterialRates: value.timeAndMaterialRates.map((inner) =>
      DetailSheetTimeAndMaterialsRateToJSON(inner)
    ),
    revamped: value.revamped,
    addedToSiteDocsDate:
      value.addedToSiteDocsDate !== null
        ? value.addedToSiteDocsDate.toISOString()
        : null,
  };
}

export const DETAIL_SHEET_META: RecordMeta<
  DetailSheet,
  DetailSheetJSON,
  DetailSheetBrokenJSON
> & { name: "DetailSheet" } = {
  name: "DetailSheet",
  type: "record",
  repair: repairDetailSheetJSON,
  toJSON: DetailSheetToJSON,
  fromJSON: JSONToDetailSheet,
  fields: {
    id: { type: "uuid" },
    recordVersion: { type: "version" },
    project: { type: "uuid", linkTo: "Project" },
    firstDate: { type: "datetime" },
    date: { type: "datetime" },
    addedDateTime: { type: "datetime" },
    modifiedDateTime: { type: "datetime" },
    options: { type: "array", items: DETAIL_SHEET_OPTION_META },
    specifierContacts: { type: "array", items: CONTACT_DETAIL_META },
    contacts: { type: "array", items: CONTACT_DETAIL_META },
    scopeOfWork: { type: "array", items: NOTE_LIST_META },
    contractNotes: { type: "array", items: NOTE_LIST_META },
    user: { type: "uuid", linkTo: "User" },
    quotations: {
      type: "array",
      items: { type: "uuid", linkTo: "Quotation" },
    },
    certifiedForeman: { type: "uuid", linkTo: "User" },
    manager: { type: "uuid", linkTo: "User" },
    managers: { type: "array", items: ROLE_WITH_PERCENTAGE_META },
    change: { type: "boolean" },
    number: { type: "quantity" },
    initialized: { type: "boolean" },
    projectedStartDate: { type: "date" },
    accessRequirements: { type: "array", items: { type: "string" } },
    requiredEquipment: { type: "array", items: { type: "string" } },
    schedules: { type: "array", items: PROJECT_SCHEDULE_META },
    contingencyItems: { type: "array", items: CONTINGENCY_ITEM_META },
    schedulesDividedDescription: { type: "boolean" },
    description: PROJECT_DESCRIPTION_DETAIL_META,
    anticipatedDuration: { type: "uuid", linkTo: "AnticipatedDuration" },
    availableWorkingDays: { type: "uuid", linkTo: "AvailableWorkingDays" },
    anticipatedCrewSize: { type: "uuid", linkTo: "AnticipatedCrewSize" },
    mockupExpectations: { type: "uuid", linkTo: "MockupExpectation" },
    mockupExpectationsNote: { type: "string" },
    mockupExpectationsDue: { type: "date" },
    projectPhases: { type: "array", items: { type: "string" } },
    preexistingConditions: { type: "array", items: { type: "string" } },
    potentialForUnforeseenWork: {
      type: "array",
      items: { type: "string" },
    },
    clientSuccessCommunications: { type: "string" },
    writtenOrVerbalPromises: { type: "string" },
    requiredEquipmentList: {
      type: "array",
      items: DETAIL_SHEET_REQUIRED_EQUIPMENT_META,
    },
    challengingLocations: { type: "string" },
    requiredNotices: {
      type: "array",
      items: { type: "uuid", linkTo: "RequiredNoticeType" },
    },
    siteStorage: { type: "string" },
    liftParkingRequired: { type: "boolean" },
    liftOvernightParkingLocation: { type: "string" },
    portableToiletRequired: { type: "boolean" },
    portableToiletProposedLocation: { type: "string" },
    locationOfOnSiteWashrooms: { type: "string" },
    parking: { type: "uuid", linkTo: "ParkingType" },
    parkingNotes: { type: "string" },
    potentialDelays: { type: "string" },
    colourApprovalProcess: { type: "string" },
    permitsRequired: { type: "boolean" },
    permitsRequiredNote: { type: "string" },
    potentialBudgetChallenges: { type: "string" },
    timeAndMaterialRates: {
      type: "array",
      items: DETAIL_SHEET_TIME_AND_MATERIALS_RATE_META,
    },
    revamped: { type: "boolean" },
    addedToSiteDocsDate: { type: "datetime" },
  },
  userFacingKey: "number",
  functions: {
    ungenerated: {
      fn: calcDetailSheetUngenerated,
      parameterTypes: () => [DETAIL_SHEET_META],
      returnType: { type: "boolean" },
    },
    contractValue: {
      fn: calcDetailSheetContractValue,
      parameterTypes: () => [DETAIL_SHEET_META],
      returnType: { type: "money" },
    },
  },
  segments: {},
};

// END MAGIC -- DO NOT EDIT
