import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { FormField, OptionalFormField } from "../../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { SwitchWidget } from "../../../clay/widgets/SwitchWidget";
import { RichTextWidget } from "../../rich-text-widget";
import { Project, PROJECT_META } from "../table";

export type Data = Project;

export const Fields = {
    iciSegmentProject: FormField(SwitchWidget),
    iciNotes: OptionalFormField(RichTextWidget),
};

function Component(props: Props) {
    return (
        <>
            <widgets.iciSegmentProject label="An ICI Segment Project" />
            <widgets.iciNotes label="Relevant Notes" />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.iciSegmentProject> &
    WidgetContext<typeof Fields.iciNotes>;
type ExtraProps = {};
type BaseState = {
    iciSegmentProject: WidgetState<typeof Fields.iciSegmentProject>;
    iciNotes: WidgetState<typeof Fields.iciNotes>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | {
          type: "ICI_SEGMENT_PROJECT";
          action: WidgetAction<typeof Fields.iciSegmentProject>;
      }
    | { type: "ICI_NOTES"; action: WidgetAction<typeof Fields.iciNotes> };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(
        Fields.iciSegmentProject,
        data.iciSegmentProject,
        cache,
        "iciSegmentProject",
        errors
    );
    subvalidate(Fields.iciNotes, data.iciNotes, cache, "iciNotes", errors);
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "ICI_SEGMENT_PROJECT": {
            const inner = Fields.iciSegmentProject.reduce(
                state.iciSegmentProject,
                data.iciSegmentProject,
                action.action,
                subcontext
            );
            return {
                state: { ...state, iciSegmentProject: inner.state },
                data: { ...data, iciSegmentProject: inner.data },
            };
        }
        case "ICI_NOTES": {
            const inner = Fields.iciNotes.reduce(
                state.iciNotes,
                data.iciNotes,
                action.action,
                subcontext
            );
            return {
                state: { ...state, iciNotes: inner.state },
                data: { ...data, iciNotes: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    iciSegmentProject: function (
        props: WidgetExtraProps<typeof Fields.iciSegmentProject> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "ICI_SEGMENT_PROJECT",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "iciSegmentProject",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.iciSegmentProject.component
                state={context.state.iciSegmentProject}
                data={context.data.iciSegmentProject}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Ici Segment Project"}
            />
        );
    },
    iciNotes: function (
        props: WidgetExtraProps<typeof Fields.iciNotes> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "ICI_NOTES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "iciNotes", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.iciNotes.component
                state={context.state.iciNotes}
                data={context.data.iciNotes}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Ici Notes"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: PROJECT_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let iciSegmentProjectState;
        {
            const inner = Fields.iciSegmentProject.initialize(
                data.iciSegmentProject,
                subcontext,
                subparameters.iciSegmentProject
            );
            iciSegmentProjectState = inner.state;
            data = { ...data, iciSegmentProject: inner.data };
        }
        let iciNotesState;
        {
            const inner = Fields.iciNotes.initialize(
                data.iciNotes,
                subcontext,
                subparameters.iciNotes
            );
            iciNotesState = inner.state;
            data = { ...data, iciNotes: inner.data };
        }
        let state = {
            initialParameters: parameters,
            iciSegmentProject: iciSegmentProjectState,
            iciNotes: iciNotesState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={PROJECT_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    iciSegmentProject: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.iciSegmentProject>
    >;
    iciNotes: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.iciNotes>
    >;
};
// END MAGIC -- DO NOT EDIT
