import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi, useQuickRecord } from "../../../clay/quick-cache";
import { RemoveButton } from "../../../clay/remove-button";
import { DropdownLinkWidget } from "../../../clay/widgets/dropdown-link-widget";
import { OptionalFormField } from "../../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { FieldRow } from "../../../clay/widgets/layout";
import { useListItemContext } from "../../../clay/widgets/ListWidget";
import { QuantityWidget } from "../../../clay/widgets/number-widget";
import { SelectWidget } from "../../../clay/widgets/SelectWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { REQUIRED_EQUIPMENT_TYPE_META } from "../types/table";
import {
    DetailSheetRequiredEquipment,
    DETAIL_SHEET_REQUIRED_EQUIPMENT_META,
} from "./table";

export type Data = DetailSheetRequiredEquipment;

export const Fields = {
    type: DropdownLinkWidget({
        meta: REQUIRED_EQUIPMENT_TYPE_META,
        label(data) {
            return data.name;
        },
    }),
    notes: OptionalFormField(TextWidget),
    size: OptionalFormField(SelectWidget([])),
    numberOfDrops: OptionalFormField(QuantityWidget),
    numberOfStages: OptionalFormField(QuantityWidget),
    buildingHeight: OptionalFormField(QuantityWidget),
};

function Component(props: Props) {
    const listItemContext = useListItemContext();
    const typeData = useQuickRecord(
        REQUIRED_EQUIPMENT_TYPE_META,
        props.data.type
    );
    return (
        <div
            {...listItemContext.draggableProps}
            style={{ border: "solid 1px black", padding: "1em" }}
        >
            <div style={{ display: "flex", gap: "0.5em" }}>
                {listItemContext.dragHandle}
                <widgets.type />
                <RemoveButton />
            </div>
            {props.data.type !== null && <widgets.notes />}
            {typeData && (
                <>
                    {typeData.sizes.length > 0 && (
                        <widgets.size
                            options={typeData.sizes.map((item) => ({
                                value: item,
                                label: item,
                            }))}
                        />
                    )}
                    <FieldRow>
                        <>
                            {typeData.showNumberOfDrops && (
                                <widgets.numberOfDrops />
                            )}
                            {typeData.showNumberOfStages && (
                                <widgets.numberOfStages />
                            )}
                            {typeData.showBuildingHeight && (
                                <widgets.buildingHeight />
                            )}
                        </>
                    </FieldRow>
                </>
            )}
        </div>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.type> &
    WidgetContext<typeof Fields.notes> &
    WidgetContext<typeof Fields.size> &
    WidgetContext<typeof Fields.numberOfDrops> &
    WidgetContext<typeof Fields.numberOfStages> &
    WidgetContext<typeof Fields.buildingHeight>;
type ExtraProps = {};
type BaseState = {
    type: WidgetState<typeof Fields.type>;
    notes: WidgetState<typeof Fields.notes>;
    size: WidgetState<typeof Fields.size>;
    numberOfDrops: WidgetState<typeof Fields.numberOfDrops>;
    numberOfStages: WidgetState<typeof Fields.numberOfStages>;
    buildingHeight: WidgetState<typeof Fields.buildingHeight>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | { type: "TYPE"; action: WidgetAction<typeof Fields.type> }
    | { type: "NOTES"; action: WidgetAction<typeof Fields.notes> }
    | { type: "SIZE"; action: WidgetAction<typeof Fields.size> }
    | {
          type: "NUMBER_OF_DROPS";
          action: WidgetAction<typeof Fields.numberOfDrops>;
      }
    | {
          type: "NUMBER_OF_STAGES";
          action: WidgetAction<typeof Fields.numberOfStages>;
      }
    | {
          type: "BUILDING_HEIGHT";
          action: WidgetAction<typeof Fields.buildingHeight>;
      };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(Fields.type, data.type, cache, "type", errors);
    subvalidate(Fields.notes, data.notes, cache, "notes", errors);
    subvalidate(Fields.size, data.size, cache, "size", errors);
    subvalidate(
        Fields.numberOfDrops,
        data.numberOfDrops,
        cache,
        "numberOfDrops",
        errors
    );
    subvalidate(
        Fields.numberOfStages,
        data.numberOfStages,
        cache,
        "numberOfStages",
        errors
    );
    subvalidate(
        Fields.buildingHeight,
        data.buildingHeight,
        cache,
        "buildingHeight",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "TYPE": {
            const inner = Fields.type.reduce(
                state.type,
                data.type,
                action.action,
                subcontext
            );
            return {
                state: { ...state, type: inner.state },
                data: { ...data, type: inner.data },
            };
        }
        case "NOTES": {
            const inner = Fields.notes.reduce(
                state.notes,
                data.notes,
                action.action,
                subcontext
            );
            return {
                state: { ...state, notes: inner.state },
                data: { ...data, notes: inner.data },
            };
        }
        case "SIZE": {
            const inner = Fields.size.reduce(
                state.size,
                data.size,
                action.action,
                subcontext
            );
            return {
                state: { ...state, size: inner.state },
                data: { ...data, size: inner.data },
            };
        }
        case "NUMBER_OF_DROPS": {
            const inner = Fields.numberOfDrops.reduce(
                state.numberOfDrops,
                data.numberOfDrops,
                action.action,
                subcontext
            );
            return {
                state: { ...state, numberOfDrops: inner.state },
                data: { ...data, numberOfDrops: inner.data },
            };
        }
        case "NUMBER_OF_STAGES": {
            const inner = Fields.numberOfStages.reduce(
                state.numberOfStages,
                data.numberOfStages,
                action.action,
                subcontext
            );
            return {
                state: { ...state, numberOfStages: inner.state },
                data: { ...data, numberOfStages: inner.data },
            };
        }
        case "BUILDING_HEIGHT": {
            const inner = Fields.buildingHeight.reduce(
                state.buildingHeight,
                data.buildingHeight,
                action.action,
                subcontext
            );
            return {
                state: { ...state, buildingHeight: inner.state },
                data: { ...data, buildingHeight: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    type: function (
        props: WidgetExtraProps<typeof Fields.type> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({ type: "TYPE", action }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "type", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.type.component
                state={context.state.type}
                data={context.data.type}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Type"}
            />
        );
    },
    notes: function (
        props: WidgetExtraProps<typeof Fields.notes> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({ type: "NOTES", action }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "notes", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.notes.component
                state={context.state.notes}
                data={context.data.notes}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Notes"}
            />
        );
    },
    size: function (
        props: WidgetExtraProps<typeof Fields.size> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({ type: "SIZE", action }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "size", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.size.component
                state={context.state.size}
                data={context.data.size}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Size"}
            />
        );
    },
    numberOfDrops: function (
        props: WidgetExtraProps<typeof Fields.numberOfDrops> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "NUMBER_OF_DROPS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "numberOfDrops", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.numberOfDrops.component
                state={context.state.numberOfDrops}
                data={context.data.numberOfDrops}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Number of Drops"}
            />
        );
    },
    numberOfStages: function (
        props: WidgetExtraProps<typeof Fields.numberOfStages> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "NUMBER_OF_STAGES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "numberOfStages", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.numberOfStages.component
                state={context.state.numberOfStages}
                data={context.data.numberOfStages}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Number of Stages"}
            />
        );
    },
    buildingHeight: function (
        props: WidgetExtraProps<typeof Fields.buildingHeight> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "BUILDING_HEIGHT",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "buildingHeight", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.buildingHeight.component
                state={context.state.buildingHeight}
                data={context.data.buildingHeight}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Building Height"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: DETAIL_SHEET_REQUIRED_EQUIPMENT_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let typeState;
        {
            const inner = Fields.type.initialize(
                data.type,
                subcontext,
                subparameters.type
            );
            typeState = inner.state;
            data = { ...data, type: inner.data };
        }
        let notesState;
        {
            const inner = Fields.notes.initialize(
                data.notes,
                subcontext,
                subparameters.notes
            );
            notesState = inner.state;
            data = { ...data, notes: inner.data };
        }
        let sizeState;
        {
            const inner = Fields.size.initialize(
                data.size,
                subcontext,
                subparameters.size
            );
            sizeState = inner.state;
            data = { ...data, size: inner.data };
        }
        let numberOfDropsState;
        {
            const inner = Fields.numberOfDrops.initialize(
                data.numberOfDrops,
                subcontext,
                subparameters.numberOfDrops
            );
            numberOfDropsState = inner.state;
            data = { ...data, numberOfDrops: inner.data };
        }
        let numberOfStagesState;
        {
            const inner = Fields.numberOfStages.initialize(
                data.numberOfStages,
                subcontext,
                subparameters.numberOfStages
            );
            numberOfStagesState = inner.state;
            data = { ...data, numberOfStages: inner.data };
        }
        let buildingHeightState;
        {
            const inner = Fields.buildingHeight.initialize(
                data.buildingHeight,
                subcontext,
                subparameters.buildingHeight
            );
            buildingHeightState = inner.state;
            data = { ...data, buildingHeight: inner.data };
        }
        let state = {
            initialParameters: parameters,
            type: typeState,
            notes: notesState,
            size: sizeState,
            numberOfDrops: numberOfDropsState,
            numberOfStages: numberOfStagesState,
            buildingHeight: buildingHeightState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext
                    meta={DETAIL_SHEET_REQUIRED_EQUIPMENT_META}
                    value={props.data}
                >
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    type: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.type>
    >;
    notes: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.notes>
    >;
    size: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.size>
    >;
    numberOfDrops: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.numberOfDrops>
    >;
    numberOfStages: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.numberOfStages>
    >;
    buildingHeight: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.buildingHeight>
    >;
};
// END MAGIC -- DO NOT EDIT
