import { User } from "@sentry/react";
import dateParse from "date-fns/parseISO";
import { Decimal } from "decimal.js";
import { Project } from "ts-morph";
import { Quantity } from "../../../clay/common";
import { Link } from "../../../clay/link";
import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../../clay/version";
import { SiteVisitReportTemplate } from "../site-visit-report/admin/table";
import {
    JSONToSiteVisitReportSection,
    repairSiteVisitReportSectionJSON,
    SiteVisitReportSection,
    SiteVisitReportSectionJSON,
    SiteVisitReportSectionToJSON,
    SITE_VISIT_REPORT_SECTION_META,
} from "../site-visit-report/table";

//!Data
export type CertifiedForemanAssessment = {
    id: UUID;
    recordVersion: Version;
    project: Link<Project>;
    firstDate: Date | null;
    date: Date | null;
    addedDateTime: Date | null;
    modifiedDateTime: Date | null;
    user: Link<User>;
    sections: SiteVisitReportSection[];
    number: Quantity;
    mobile: boolean;
    template: Link<SiteVisitReportTemplate>;
};

// BEGIN MAGIC -- DO NOT EDIT
export type CertifiedForemanAssessmentJSON = {
    id: string;
    recordVersion: number | null;
    project: string | null;
    firstDate: string | null;
    date: string | null;
    addedDateTime: string | null;
    modifiedDateTime: string | null;
    user: string | null;
    sections: SiteVisitReportSectionJSON[];
    number: string;
    mobile: boolean;
    template: string | null;
};

export function JSONToCertifiedForemanAssessment(
    json: CertifiedForemanAssessmentJSON
): CertifiedForemanAssessment {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        project: json.project,
        firstDate: json.firstDate !== null ? dateParse(json.firstDate) : null,
        date: json.date !== null ? dateParse(json.date) : null,
        addedDateTime:
            json.addedDateTime !== null ? dateParse(json.addedDateTime) : null,
        modifiedDateTime:
            json.modifiedDateTime !== null
                ? dateParse(json.modifiedDateTime)
                : null,
        user: json.user,
        sections: json.sections.map((inner) =>
            JSONToSiteVisitReportSection(inner)
        ),
        number: new Decimal(json.number),
        mobile: json.mobile,
        template: json.template,
    };
}
export type CertifiedForemanAssessmentBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    project?: string | null;
    firstDate?: string | null;
    date?: string | null;
    addedDateTime?: string | null;
    modifiedDateTime?: string | null;
    user?: string | null;
    sections?: SiteVisitReportSectionJSON[];
    number?: string;
    mobile?: boolean;
    template?: string | null;
};

export function newCertifiedForemanAssessment(): CertifiedForemanAssessment {
    return JSONToCertifiedForemanAssessment(
        repairCertifiedForemanAssessmentJSON(undefined)
    );
}
export function repairCertifiedForemanAssessmentJSON(
    json: CertifiedForemanAssessmentBrokenJSON | undefined
): CertifiedForemanAssessmentJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            project: json.project || null,
            firstDate: json.firstDate
                ? new Date(json.firstDate!).toISOString()
                : null,
            date: json.date ? new Date(json.date!).toISOString() : null,
            addedDateTime: json.addedDateTime
                ? new Date(json.addedDateTime!).toISOString()
                : null,
            modifiedDateTime: json.modifiedDateTime
                ? new Date(json.modifiedDateTime!).toISOString()
                : null,
            user: json.user || null,
            sections: (json.sections || []).map((inner) =>
                repairSiteVisitReportSectionJSON(inner)
            ),
            number: json.number || "0",
            mobile: json.mobile || false,
            template: json.template || null,
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            project: undefined || null,
            firstDate: undefined ? new Date(undefined!).toISOString() : null,
            date: undefined ? new Date(undefined!).toISOString() : null,
            addedDateTime: undefined
                ? new Date(undefined!).toISOString()
                : null,
            modifiedDateTime: undefined
                ? new Date(undefined!).toISOString()
                : null,
            user: undefined || null,
            sections: (undefined || []).map((inner) =>
                repairSiteVisitReportSectionJSON(inner)
            ),
            number: undefined || "0",
            mobile: undefined || false,
            template: undefined || null,
        };
    }
}

export function CertifiedForemanAssessmentToJSON(
    value: CertifiedForemanAssessment
): CertifiedForemanAssessmentJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        project: value.project,
        firstDate:
            value.firstDate !== null ? value.firstDate.toISOString() : null,
        date: value.date !== null ? value.date.toISOString() : null,
        addedDateTime:
            value.addedDateTime !== null
                ? value.addedDateTime.toISOString()
                : null,
        modifiedDateTime:
            value.modifiedDateTime !== null
                ? value.modifiedDateTime.toISOString()
                : null,
        user: value.user,
        sections: value.sections.map((inner) =>
            SiteVisitReportSectionToJSON(inner)
        ),
        number: value.number.toString(),
        mobile: value.mobile,
        template: value.template,
    };
}

export const CERTIFIED_FOREMAN_ASSESSMENT_META: RecordMeta<
    CertifiedForemanAssessment,
    CertifiedForemanAssessmentJSON,
    CertifiedForemanAssessmentBrokenJSON
> & { name: "CertifiedForemanAssessment" } = {
    name: "CertifiedForemanAssessment",
    type: "record",
    repair: repairCertifiedForemanAssessmentJSON,
    toJSON: CertifiedForemanAssessmentToJSON,
    fromJSON: JSONToCertifiedForemanAssessment,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        project: { type: "uuid", linkTo: "Project" },
        firstDate: { type: "datetime" },
        date: { type: "datetime" },
        addedDateTime: { type: "datetime" },
        modifiedDateTime: { type: "datetime" },
        user: { type: "uuid", linkTo: "User" },
        sections: { type: "array", items: SITE_VISIT_REPORT_SECTION_META },
        number: { type: "quantity" },
        mobile: { type: "boolean" },
        template: { type: "uuid", linkTo: "SiteVisitReportTemplate" },
    },
    userFacingKey: "number",
    functions: {},
    segments: {},
};

// END MAGIC -- DO NOT EDIT
