import { Decimal } from "decimal.js";
import { Money, Percentage } from "../../../clay/common";
import { Link } from "../../../clay/link";
import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { MasterFormatCode } from "../../project/master-format-codes/table";
import { SpectrumInvoiceType } from "../../project/spectrum/table";
import { Area } from "../area/table";
import { ItemType } from "../types/table";

//!Data
export type Allowance = {
    id: UUID;
    masterFormatCode: Link<MasterFormatCode>;
    itemType: Link<ItemType>;
    name: string;
    cost: Money;
    markup: Money;
    areas: Link<Area>[];
    nonCfExpense: boolean;
    spectrumType: Link<SpectrumInvoiceType>;
};

export function allowanceCost(allowance: Allowance) {
    const multiplier =
        allowance.areas.length === 0 ? 1 : allowance.areas.length;
    return allowance.cost.times(multiplier);
}

export function allowancePrice(allowance: Allowance, markup: Percentage) {
    return allowanceCost(allowance)
        .times(
            new Decimal(1)
                .plus(allowance.markup)
                .times(new Decimal(1).plus(markup))
        )
        .toDecimalPlaces(2);
}

// BEGIN MAGIC -- DO NOT EDIT
export type AllowanceJSON = {
    id: string;
    masterFormatCode: string | null;
    itemType: string | null;
    name: string;
    cost: string;
    markup: string;
    areas: (string | null)[];
    nonCfExpense: boolean;
    spectrumType: string | null;
};

export function JSONToAllowance(json: AllowanceJSON): Allowance {
    return {
        id: { uuid: json.id },
        masterFormatCode: json.masterFormatCode,
        itemType: json.itemType,
        name: json.name,
        cost: new Decimal(json.cost),
        markup: new Decimal(json.markup),
        areas: json.areas.map((inner) => inner),
        nonCfExpense: json.nonCfExpense,
        spectrumType: json.spectrumType,
    };
}
export type AllowanceBrokenJSON = {
    id?: string;
    masterFormatCode?: string | null;
    itemType?: string | null;
    name?: string;
    cost?: string;
    markup?: string;
    areas?: (string | null)[];
    nonCfExpense?: boolean;
    spectrumType?: string | null;
};

export function newAllowance(): Allowance {
    return JSONToAllowance(repairAllowanceJSON(undefined));
}
export function repairAllowanceJSON(
    json: AllowanceBrokenJSON | undefined
): AllowanceJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            masterFormatCode: json.masterFormatCode || null,
            itemType: json.itemType || null,
            name: json.name || "",
            cost: json.cost || "0",
            markup: json.markup || "0",
            areas: (json.areas || []).map((inner) => inner || null),
            nonCfExpense: json.nonCfExpense || false,
            spectrumType: json.spectrumType || null,
        };
    } else {
        return {
            id: undefined || genUUID(),
            masterFormatCode: undefined || null,
            itemType: undefined || null,
            name: undefined || "",
            cost: undefined || "0",
            markup: undefined || "0",
            areas: (undefined || []).map((inner) => inner || null),
            nonCfExpense: undefined || false,
            spectrumType: undefined || null,
        };
    }
}

export function AllowanceToJSON(value: Allowance): AllowanceJSON {
    return {
        id: value.id.uuid,
        masterFormatCode: value.masterFormatCode,
        itemType: value.itemType,
        name: value.name,
        cost: value.cost.toString(),
        markup: value.markup.toString(),
        areas: value.areas.map((inner) => inner),
        nonCfExpense: value.nonCfExpense,
        spectrumType: value.spectrumType,
    };
}

export const ALLOWANCE_META: RecordMeta<
    Allowance,
    AllowanceJSON,
    AllowanceBrokenJSON
> & { name: "Allowance" } = {
    name: "Allowance",
    type: "record",
    repair: repairAllowanceJSON,
    toJSON: AllowanceToJSON,
    fromJSON: JSONToAllowance,
    fields: {
        id: { type: "uuid" },
        masterFormatCode: { type: "uuid", linkTo: "MasterFormatCode" },
        itemType: { type: "uuid", linkTo: "ItemType" },
        name: { type: "string" },
        cost: { type: "money" },
        markup: { type: "money" },
        areas: { type: "array", items: { type: "uuid", linkTo: "Area" } },
        nonCfExpense: { type: "boolean" },
        spectrumType: { type: "uuid", linkTo: "SpectrumInvoiceType" },
    },
    userFacingKey: "name",
    functions: {},
    segments: {},
};

// END MAGIC -- DO NOT EDIT
