import * as React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { DateWidget } from "../../../clay/widgets/DateWidget";
import { simpleLinkWidget } from "../../../clay/widgets/dropdown-link-widget";
import {
    FormField,
    FormWrapper,
    OptionalFormField,
} from "../../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { FieldRow } from "../../../clay/widgets/layout";
import { LinkSetWidget } from "../../../clay/widgets/link-set-widget";
import {
    ListWidget,
    useListItemContext,
} from "../../../clay/widgets/ListWidget";
import { SimpleListWrapper } from "../../../clay/widgets/SimpleListWrapper";
import { SwitchWidget } from "../../../clay/widgets/SwitchWidget";
import { TextAreaWidget } from "../../../clay/widgets/TextAreaWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { RichTextWidget } from "../../rich-text-widget";
import { AnticipatedDurationLinkWidget } from "../types/link";
import {
    ANTICIPATED_CREW_SIZE_META,
    AVAILABLE_WORKING_DAYS_META,
    MOCKUP_EXPECTATION_META,
    PARKING_TYPE_META,
    REQUIRED_NOTICE_TYPE_META,
} from "../types/table";
import RequiredEquipmentWidget from "./RequiredEquipmentWidget.widget";
import { DetailSheet, DETAIL_SHEET_META } from "./table";

export type Data = DetailSheet;

function validate(data: DetailSheet, cache: QuickCacheApi) {
    const errors = baseValidate(data, cache);
    return errors.filter((error) => {
        switch (error.field) {
            case "liftOvernightParkingLocation":
                return data.liftParkingRequired;
            case "portableToilerProposedLocation":
                return data.portableToiletRequired;
            case "locationOfOnSiteWashrooms":
                return !data.portableToiletRequired;
            case "permitsRequiredNotes":
                return data.permitsRequired;
            default:
                return true;
        }
    });
}
function Numbered() {
    const listItemContext = useListItemContext();
    return <>{listItemContext.index}. </>;
}
const NumberedListWidget = FormField(
    ListWidget(SimpleListWrapper(TextWidget, undefined, true), {
        emptyOk: true,
    })
);

export const Fields = {
    projectedStartDate: OptionalFormField(DateWidget),
    anticipatedDuration: OptionalFormField(AnticipatedDurationLinkWidget),
    availableWorkingDays: OptionalFormField(
        simpleLinkWidget(AVAILABLE_WORKING_DAYS_META)
    ),
    anticipatedCrewSize: OptionalFormField(
        simpleLinkWidget(ANTICIPATED_CREW_SIZE_META)
    ),
    mockupExpectations: OptionalFormField(
        simpleLinkWidget(MOCKUP_EXPECTATION_META)
    ),
    mockupExpectationsNote: OptionalFormField(TextWidget),
    mockupExpectationsDue: OptionalFormField(DateWidget),
    projectPhases: NumberedListWidget,
    preexistingConditions: NumberedListWidget,
    potentialForUnforeseenWork: NumberedListWidget,

    clientSuccessCommunications: OptionalFormField(RichTextWidget),
    writtenOrVerbalPromises: OptionalFormField(RichTextWidget),

    requiredEquipmentList: ListWidget(RequiredEquipmentWidget, {
        emptyOk: true,
    }),

    challengingLocations: OptionalFormField(TextAreaWidget),
    requiredNotices: OptionalFormField(
        LinkSetWidget({
            meta: REQUIRED_NOTICE_TYPE_META,
            name(record) {
                return record.name;
            },
            filter(record) {
                return record.active;
            },
        })
    ),
    siteStorage: OptionalFormField(TextAreaWidget),
    liftParkingRequired: OptionalFormField(SwitchWidget),
    liftOvernightParkingLocation: OptionalFormField(TextWidget),
    portableToiletRequired: OptionalFormField(SwitchWidget),
    portableToiletProposedLocation: OptionalFormField(TextWidget),
    locationOfOnSiteWashrooms: OptionalFormField(TextWidget),
    parking: OptionalFormField(simpleLinkWidget(PARKING_TYPE_META)),
    parkingNotes: OptionalFormField(TextAreaWidget),

    potentialDelays: OptionalFormField(TextAreaWidget),
    colourApprovalProcess: OptionalFormField(TextAreaWidget),
    permitsRequired: OptionalFormField(SwitchWidget),
    permitsRequiredNote: OptionalFormField(TextAreaWidget),
    potentialBudgetChallenges: OptionalFormField(TextAreaWidget),
};

function Component(props: Props) {
    return (
        <Tabs defaultActiveKey={"projectDetails"}>
            <Tab title="Project Details" eventKey={"projectDetails"}>
                <FieldRow>
                    <widgets.projectedStartDate />
                    <widgets.anticipatedDuration />
                </FieldRow>
                <FieldRow>
                    <widgets.availableWorkingDays label="Available Working Days/Hours" />
                    <widgets.anticipatedCrewSize />
                </FieldRow>
                <FieldRow>
                    <widgets.mockupExpectations label="Mock-up Expectations" />
                    <widgets.mockupExpectationsNote label="Note" />
                    <widgets.mockupExpectationsDue label="Due Date" />
                </FieldRow>
                <widgets.projectPhases extraItemForAdd />
                <widgets.preexistingConditions
                    label="Pre-existing Conditions"
                    extraItemForAdd
                />
                <widgets.potentialForUnforeseenWork extraItemForAdd />
            </Tab>
            <Tab title="Client Success" eventKey="client-success">
                <widgets.clientSuccessCommunications label="Communications" />
                <widgets.writtenOrVerbalPromises />
            </Tab>
            <Tab title="Access" eventKey="access">
                <FormWrapper label="Required Equipment">
                    <widgets.requiredEquipmentList extraItemForAdd />
                </FormWrapper>
                <widgets.challengingLocations />
            </Tab>
            <Tab title="Mobilization" eventKey="mobilization">
                <widgets.requiredNotices />
                <widgets.siteStorage />
                <FieldRow>
                    <widgets.liftParkingRequired label="Lift Parking Required?" />
                    {props.data.liftParkingRequired && (
                        <widgets.liftOvernightParkingLocation label="Overnight Parking Location" />
                    )}
                </FieldRow>
                <FieldRow>
                    <widgets.portableToiletRequired label="Portable Toilet Required?" />
                    {props.data.portableToiletRequired ? (
                        <widgets.portableToiletProposedLocation label="Proposed Location" />
                    ) : (
                        <widgets.locationOfOnSiteWashrooms label="Location of On-Site Washrooms" />
                    )}
                </FieldRow>
                <FieldRow>
                    <widgets.parking />
                    <widgets.parkingNotes label="Notes" />
                </FieldRow>
            </Tab>
            <Tab title="Concerns" eventKey="concerns">
                <widgets.potentialDelays />
                <widgets.colourApprovalProcess />
                <FieldRow>
                    <widgets.permitsRequired />
                    {props.data.permitsRequired && (
                        <widgets.permitsRequiredNote />
                    )}
                </FieldRow>
                <widgets.potentialBudgetChallenges />
            </Tab>
        </Tabs>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.projectedStartDate> &
    WidgetContext<typeof Fields.anticipatedDuration> &
    WidgetContext<typeof Fields.availableWorkingDays> &
    WidgetContext<typeof Fields.anticipatedCrewSize> &
    WidgetContext<typeof Fields.mockupExpectations> &
    WidgetContext<typeof Fields.mockupExpectationsNote> &
    WidgetContext<typeof Fields.mockupExpectationsDue> &
    WidgetContext<typeof Fields.projectPhases> &
    WidgetContext<typeof Fields.preexistingConditions> &
    WidgetContext<typeof Fields.potentialForUnforeseenWork> &
    WidgetContext<typeof Fields.clientSuccessCommunications> &
    WidgetContext<typeof Fields.writtenOrVerbalPromises> &
    WidgetContext<typeof Fields.requiredEquipmentList> &
    WidgetContext<typeof Fields.challengingLocations> &
    WidgetContext<typeof Fields.requiredNotices> &
    WidgetContext<typeof Fields.siteStorage> &
    WidgetContext<typeof Fields.liftParkingRequired> &
    WidgetContext<typeof Fields.liftOvernightParkingLocation> &
    WidgetContext<typeof Fields.portableToiletRequired> &
    WidgetContext<typeof Fields.portableToiletProposedLocation> &
    WidgetContext<typeof Fields.locationOfOnSiteWashrooms> &
    WidgetContext<typeof Fields.parking> &
    WidgetContext<typeof Fields.parkingNotes> &
    WidgetContext<typeof Fields.potentialDelays> &
    WidgetContext<typeof Fields.colourApprovalProcess> &
    WidgetContext<typeof Fields.permitsRequired> &
    WidgetContext<typeof Fields.permitsRequiredNote> &
    WidgetContext<typeof Fields.potentialBudgetChallenges>;
type ExtraProps = {};
type BaseState = {
    projectedStartDate: WidgetState<typeof Fields.projectedStartDate>;
    anticipatedDuration: WidgetState<typeof Fields.anticipatedDuration>;
    availableWorkingDays: WidgetState<typeof Fields.availableWorkingDays>;
    anticipatedCrewSize: WidgetState<typeof Fields.anticipatedCrewSize>;
    mockupExpectations: WidgetState<typeof Fields.mockupExpectations>;
    mockupExpectationsNote: WidgetState<typeof Fields.mockupExpectationsNote>;
    mockupExpectationsDue: WidgetState<typeof Fields.mockupExpectationsDue>;
    projectPhases: WidgetState<typeof Fields.projectPhases>;
    preexistingConditions: WidgetState<typeof Fields.preexistingConditions>;
    potentialForUnforeseenWork: WidgetState<
        typeof Fields.potentialForUnforeseenWork
    >;
    clientSuccessCommunications: WidgetState<
        typeof Fields.clientSuccessCommunications
    >;
    writtenOrVerbalPromises: WidgetState<typeof Fields.writtenOrVerbalPromises>;
    requiredEquipmentList: WidgetState<typeof Fields.requiredEquipmentList>;
    challengingLocations: WidgetState<typeof Fields.challengingLocations>;
    requiredNotices: WidgetState<typeof Fields.requiredNotices>;
    siteStorage: WidgetState<typeof Fields.siteStorage>;
    liftParkingRequired: WidgetState<typeof Fields.liftParkingRequired>;
    liftOvernightParkingLocation: WidgetState<
        typeof Fields.liftOvernightParkingLocation
    >;
    portableToiletRequired: WidgetState<typeof Fields.portableToiletRequired>;
    portableToiletProposedLocation: WidgetState<
        typeof Fields.portableToiletProposedLocation
    >;
    locationOfOnSiteWashrooms: WidgetState<
        typeof Fields.locationOfOnSiteWashrooms
    >;
    parking: WidgetState<typeof Fields.parking>;
    parkingNotes: WidgetState<typeof Fields.parkingNotes>;
    potentialDelays: WidgetState<typeof Fields.potentialDelays>;
    colourApprovalProcess: WidgetState<typeof Fields.colourApprovalProcess>;
    permitsRequired: WidgetState<typeof Fields.permitsRequired>;
    permitsRequiredNote: WidgetState<typeof Fields.permitsRequiredNote>;
    potentialBudgetChallenges: WidgetState<
        typeof Fields.potentialBudgetChallenges
    >;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | {
          type: "PROJECTED_START_DATE";
          action: WidgetAction<typeof Fields.projectedStartDate>;
      }
    | {
          type: "ANTICIPATED_DURATION";
          action: WidgetAction<typeof Fields.anticipatedDuration>;
      }
    | {
          type: "AVAILABLE_WORKING_DAYS";
          action: WidgetAction<typeof Fields.availableWorkingDays>;
      }
    | {
          type: "ANTICIPATED_CREW_SIZE";
          action: WidgetAction<typeof Fields.anticipatedCrewSize>;
      }
    | {
          type: "MOCKUP_EXPECTATIONS";
          action: WidgetAction<typeof Fields.mockupExpectations>;
      }
    | {
          type: "MOCKUP_EXPECTATIONS_NOTE";
          action: WidgetAction<typeof Fields.mockupExpectationsNote>;
      }
    | {
          type: "MOCKUP_EXPECTATIONS_DUE";
          action: WidgetAction<typeof Fields.mockupExpectationsDue>;
      }
    | {
          type: "PROJECT_PHASES";
          action: WidgetAction<typeof Fields.projectPhases>;
      }
    | {
          type: "PREEXISTING_CONDITIONS";
          action: WidgetAction<typeof Fields.preexistingConditions>;
      }
    | {
          type: "POTENTIAL_FOR_UNFORESEEN_WORK";
          action: WidgetAction<typeof Fields.potentialForUnforeseenWork>;
      }
    | {
          type: "CLIENT_SUCCESS_COMMUNICATIONS";
          action: WidgetAction<typeof Fields.clientSuccessCommunications>;
      }
    | {
          type: "WRITTEN_OR_VERBAL_PROMISES";
          action: WidgetAction<typeof Fields.writtenOrVerbalPromises>;
      }
    | {
          type: "REQUIRED_EQUIPMENT_LIST";
          action: WidgetAction<typeof Fields.requiredEquipmentList>;
      }
    | {
          type: "CHALLENGING_LOCATIONS";
          action: WidgetAction<typeof Fields.challengingLocations>;
      }
    | {
          type: "REQUIRED_NOTICES";
          action: WidgetAction<typeof Fields.requiredNotices>;
      }
    | { type: "SITE_STORAGE"; action: WidgetAction<typeof Fields.siteStorage> }
    | {
          type: "LIFT_PARKING_REQUIRED";
          action: WidgetAction<typeof Fields.liftParkingRequired>;
      }
    | {
          type: "LIFT_OVERNIGHT_PARKING_LOCATION";
          action: WidgetAction<typeof Fields.liftOvernightParkingLocation>;
      }
    | {
          type: "PORTABLE_TOILET_REQUIRED";
          action: WidgetAction<typeof Fields.portableToiletRequired>;
      }
    | {
          type: "PORTABLE_TOILET_PROPOSED_LOCATION";
          action: WidgetAction<typeof Fields.portableToiletProposedLocation>;
      }
    | {
          type: "LOCATION_OF_ON_SITE_WASHROOMS";
          action: WidgetAction<typeof Fields.locationOfOnSiteWashrooms>;
      }
    | { type: "PARKING"; action: WidgetAction<typeof Fields.parking> }
    | {
          type: "PARKING_NOTES";
          action: WidgetAction<typeof Fields.parkingNotes>;
      }
    | {
          type: "POTENTIAL_DELAYS";
          action: WidgetAction<typeof Fields.potentialDelays>;
      }
    | {
          type: "COLOUR_APPROVAL_PROCESS";
          action: WidgetAction<typeof Fields.colourApprovalProcess>;
      }
    | {
          type: "PERMITS_REQUIRED";
          action: WidgetAction<typeof Fields.permitsRequired>;
      }
    | {
          type: "PERMITS_REQUIRED_NOTE";
          action: WidgetAction<typeof Fields.permitsRequiredNote>;
      }
    | {
          type: "POTENTIAL_BUDGET_CHALLENGES";
          action: WidgetAction<typeof Fields.potentialBudgetChallenges>;
      };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(
        Fields.projectedStartDate,
        data.projectedStartDate,
        cache,
        "projectedStartDate",
        errors
    );
    subvalidate(
        Fields.anticipatedDuration,
        data.anticipatedDuration,
        cache,
        "anticipatedDuration",
        errors
    );
    subvalidate(
        Fields.availableWorkingDays,
        data.availableWorkingDays,
        cache,
        "availableWorkingDays",
        errors
    );
    subvalidate(
        Fields.anticipatedCrewSize,
        data.anticipatedCrewSize,
        cache,
        "anticipatedCrewSize",
        errors
    );
    subvalidate(
        Fields.mockupExpectations,
        data.mockupExpectations,
        cache,
        "mockupExpectations",
        errors
    );
    subvalidate(
        Fields.mockupExpectationsNote,
        data.mockupExpectationsNote,
        cache,
        "mockupExpectationsNote",
        errors
    );
    subvalidate(
        Fields.mockupExpectationsDue,
        data.mockupExpectationsDue,
        cache,
        "mockupExpectationsDue",
        errors
    );
    subvalidate(
        Fields.projectPhases,
        data.projectPhases,
        cache,
        "projectPhases",
        errors
    );
    subvalidate(
        Fields.preexistingConditions,
        data.preexistingConditions,
        cache,
        "preexistingConditions",
        errors
    );
    subvalidate(
        Fields.potentialForUnforeseenWork,
        data.potentialForUnforeseenWork,
        cache,
        "potentialForUnforeseenWork",
        errors
    );
    subvalidate(
        Fields.clientSuccessCommunications,
        data.clientSuccessCommunications,
        cache,
        "clientSuccessCommunications",
        errors
    );
    subvalidate(
        Fields.writtenOrVerbalPromises,
        data.writtenOrVerbalPromises,
        cache,
        "writtenOrVerbalPromises",
        errors
    );
    subvalidate(
        Fields.requiredEquipmentList,
        data.requiredEquipmentList,
        cache,
        "requiredEquipmentList",
        errors
    );
    subvalidate(
        Fields.challengingLocations,
        data.challengingLocations,
        cache,
        "challengingLocations",
        errors
    );
    subvalidate(
        Fields.requiredNotices,
        data.requiredNotices,
        cache,
        "requiredNotices",
        errors
    );
    subvalidate(
        Fields.siteStorage,
        data.siteStorage,
        cache,
        "siteStorage",
        errors
    );
    subvalidate(
        Fields.liftParkingRequired,
        data.liftParkingRequired,
        cache,
        "liftParkingRequired",
        errors
    );
    subvalidate(
        Fields.liftOvernightParkingLocation,
        data.liftOvernightParkingLocation,
        cache,
        "liftOvernightParkingLocation",
        errors
    );
    subvalidate(
        Fields.portableToiletRequired,
        data.portableToiletRequired,
        cache,
        "portableToiletRequired",
        errors
    );
    subvalidate(
        Fields.portableToiletProposedLocation,
        data.portableToiletProposedLocation,
        cache,
        "portableToiletProposedLocation",
        errors
    );
    subvalidate(
        Fields.locationOfOnSiteWashrooms,
        data.locationOfOnSiteWashrooms,
        cache,
        "locationOfOnSiteWashrooms",
        errors
    );
    subvalidate(Fields.parking, data.parking, cache, "parking", errors);
    subvalidate(
        Fields.parkingNotes,
        data.parkingNotes,
        cache,
        "parkingNotes",
        errors
    );
    subvalidate(
        Fields.potentialDelays,
        data.potentialDelays,
        cache,
        "potentialDelays",
        errors
    );
    subvalidate(
        Fields.colourApprovalProcess,
        data.colourApprovalProcess,
        cache,
        "colourApprovalProcess",
        errors
    );
    subvalidate(
        Fields.permitsRequired,
        data.permitsRequired,
        cache,
        "permitsRequired",
        errors
    );
    subvalidate(
        Fields.permitsRequiredNote,
        data.permitsRequiredNote,
        cache,
        "permitsRequiredNote",
        errors
    );
    subvalidate(
        Fields.potentialBudgetChallenges,
        data.potentialBudgetChallenges,
        cache,
        "potentialBudgetChallenges",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "PROJECTED_START_DATE": {
            const inner = Fields.projectedStartDate.reduce(
                state.projectedStartDate,
                data.projectedStartDate,
                action.action,
                subcontext
            );
            return {
                state: { ...state, projectedStartDate: inner.state },
                data: { ...data, projectedStartDate: inner.data },
            };
        }
        case "ANTICIPATED_DURATION": {
            const inner = Fields.anticipatedDuration.reduce(
                state.anticipatedDuration,
                data.anticipatedDuration,
                action.action,
                subcontext
            );
            return {
                state: { ...state, anticipatedDuration: inner.state },
                data: { ...data, anticipatedDuration: inner.data },
            };
        }
        case "AVAILABLE_WORKING_DAYS": {
            const inner = Fields.availableWorkingDays.reduce(
                state.availableWorkingDays,
                data.availableWorkingDays,
                action.action,
                subcontext
            );
            return {
                state: { ...state, availableWorkingDays: inner.state },
                data: { ...data, availableWorkingDays: inner.data },
            };
        }
        case "ANTICIPATED_CREW_SIZE": {
            const inner = Fields.anticipatedCrewSize.reduce(
                state.anticipatedCrewSize,
                data.anticipatedCrewSize,
                action.action,
                subcontext
            );
            return {
                state: { ...state, anticipatedCrewSize: inner.state },
                data: { ...data, anticipatedCrewSize: inner.data },
            };
        }
        case "MOCKUP_EXPECTATIONS": {
            const inner = Fields.mockupExpectations.reduce(
                state.mockupExpectations,
                data.mockupExpectations,
                action.action,
                subcontext
            );
            return {
                state: { ...state, mockupExpectations: inner.state },
                data: { ...data, mockupExpectations: inner.data },
            };
        }
        case "MOCKUP_EXPECTATIONS_NOTE": {
            const inner = Fields.mockupExpectationsNote.reduce(
                state.mockupExpectationsNote,
                data.mockupExpectationsNote,
                action.action,
                subcontext
            );
            return {
                state: { ...state, mockupExpectationsNote: inner.state },
                data: { ...data, mockupExpectationsNote: inner.data },
            };
        }
        case "MOCKUP_EXPECTATIONS_DUE": {
            const inner = Fields.mockupExpectationsDue.reduce(
                state.mockupExpectationsDue,
                data.mockupExpectationsDue,
                action.action,
                subcontext
            );
            return {
                state: { ...state, mockupExpectationsDue: inner.state },
                data: { ...data, mockupExpectationsDue: inner.data },
            };
        }
        case "PROJECT_PHASES": {
            const inner = Fields.projectPhases.reduce(
                state.projectPhases,
                data.projectPhases,
                action.action,
                subcontext
            );
            return {
                state: { ...state, projectPhases: inner.state },
                data: { ...data, projectPhases: inner.data },
            };
        }
        case "PREEXISTING_CONDITIONS": {
            const inner = Fields.preexistingConditions.reduce(
                state.preexistingConditions,
                data.preexistingConditions,
                action.action,
                subcontext
            );
            return {
                state: { ...state, preexistingConditions: inner.state },
                data: { ...data, preexistingConditions: inner.data },
            };
        }
        case "POTENTIAL_FOR_UNFORESEEN_WORK": {
            const inner = Fields.potentialForUnforeseenWork.reduce(
                state.potentialForUnforeseenWork,
                data.potentialForUnforeseenWork,
                action.action,
                subcontext
            );
            return {
                state: { ...state, potentialForUnforeseenWork: inner.state },
                data: { ...data, potentialForUnforeseenWork: inner.data },
            };
        }
        case "CLIENT_SUCCESS_COMMUNICATIONS": {
            const inner = Fields.clientSuccessCommunications.reduce(
                state.clientSuccessCommunications,
                data.clientSuccessCommunications,
                action.action,
                subcontext
            );
            return {
                state: { ...state, clientSuccessCommunications: inner.state },
                data: { ...data, clientSuccessCommunications: inner.data },
            };
        }
        case "WRITTEN_OR_VERBAL_PROMISES": {
            const inner = Fields.writtenOrVerbalPromises.reduce(
                state.writtenOrVerbalPromises,
                data.writtenOrVerbalPromises,
                action.action,
                subcontext
            );
            return {
                state: { ...state, writtenOrVerbalPromises: inner.state },
                data: { ...data, writtenOrVerbalPromises: inner.data },
            };
        }
        case "REQUIRED_EQUIPMENT_LIST": {
            const inner = Fields.requiredEquipmentList.reduce(
                state.requiredEquipmentList,
                data.requiredEquipmentList,
                action.action,
                subcontext
            );
            return {
                state: { ...state, requiredEquipmentList: inner.state },
                data: { ...data, requiredEquipmentList: inner.data },
            };
        }
        case "CHALLENGING_LOCATIONS": {
            const inner = Fields.challengingLocations.reduce(
                state.challengingLocations,
                data.challengingLocations,
                action.action,
                subcontext
            );
            return {
                state: { ...state, challengingLocations: inner.state },
                data: { ...data, challengingLocations: inner.data },
            };
        }
        case "REQUIRED_NOTICES": {
            const inner = Fields.requiredNotices.reduce(
                state.requiredNotices,
                data.requiredNotices,
                action.action,
                subcontext
            );
            return {
                state: { ...state, requiredNotices: inner.state },
                data: { ...data, requiredNotices: inner.data },
            };
        }
        case "SITE_STORAGE": {
            const inner = Fields.siteStorage.reduce(
                state.siteStorage,
                data.siteStorage,
                action.action,
                subcontext
            );
            return {
                state: { ...state, siteStorage: inner.state },
                data: { ...data, siteStorage: inner.data },
            };
        }
        case "LIFT_PARKING_REQUIRED": {
            const inner = Fields.liftParkingRequired.reduce(
                state.liftParkingRequired,
                data.liftParkingRequired,
                action.action,
                subcontext
            );
            return {
                state: { ...state, liftParkingRequired: inner.state },
                data: { ...data, liftParkingRequired: inner.data },
            };
        }
        case "LIFT_OVERNIGHT_PARKING_LOCATION": {
            const inner = Fields.liftOvernightParkingLocation.reduce(
                state.liftOvernightParkingLocation,
                data.liftOvernightParkingLocation,
                action.action,
                subcontext
            );
            return {
                state: { ...state, liftOvernightParkingLocation: inner.state },
                data: { ...data, liftOvernightParkingLocation: inner.data },
            };
        }
        case "PORTABLE_TOILET_REQUIRED": {
            const inner = Fields.portableToiletRequired.reduce(
                state.portableToiletRequired,
                data.portableToiletRequired,
                action.action,
                subcontext
            );
            return {
                state: { ...state, portableToiletRequired: inner.state },
                data: { ...data, portableToiletRequired: inner.data },
            };
        }
        case "PORTABLE_TOILET_PROPOSED_LOCATION": {
            const inner = Fields.portableToiletProposedLocation.reduce(
                state.portableToiletProposedLocation,
                data.portableToiletProposedLocation,
                action.action,
                subcontext
            );
            return {
                state: {
                    ...state,
                    portableToiletProposedLocation: inner.state,
                },
                data: { ...data, portableToiletProposedLocation: inner.data },
            };
        }
        case "LOCATION_OF_ON_SITE_WASHROOMS": {
            const inner = Fields.locationOfOnSiteWashrooms.reduce(
                state.locationOfOnSiteWashrooms,
                data.locationOfOnSiteWashrooms,
                action.action,
                subcontext
            );
            return {
                state: { ...state, locationOfOnSiteWashrooms: inner.state },
                data: { ...data, locationOfOnSiteWashrooms: inner.data },
            };
        }
        case "PARKING": {
            const inner = Fields.parking.reduce(
                state.parking,
                data.parking,
                action.action,
                subcontext
            );
            return {
                state: { ...state, parking: inner.state },
                data: { ...data, parking: inner.data },
            };
        }
        case "PARKING_NOTES": {
            const inner = Fields.parkingNotes.reduce(
                state.parkingNotes,
                data.parkingNotes,
                action.action,
                subcontext
            );
            return {
                state: { ...state, parkingNotes: inner.state },
                data: { ...data, parkingNotes: inner.data },
            };
        }
        case "POTENTIAL_DELAYS": {
            const inner = Fields.potentialDelays.reduce(
                state.potentialDelays,
                data.potentialDelays,
                action.action,
                subcontext
            );
            return {
                state: { ...state, potentialDelays: inner.state },
                data: { ...data, potentialDelays: inner.data },
            };
        }
        case "COLOUR_APPROVAL_PROCESS": {
            const inner = Fields.colourApprovalProcess.reduce(
                state.colourApprovalProcess,
                data.colourApprovalProcess,
                action.action,
                subcontext
            );
            return {
                state: { ...state, colourApprovalProcess: inner.state },
                data: { ...data, colourApprovalProcess: inner.data },
            };
        }
        case "PERMITS_REQUIRED": {
            const inner = Fields.permitsRequired.reduce(
                state.permitsRequired,
                data.permitsRequired,
                action.action,
                subcontext
            );
            return {
                state: { ...state, permitsRequired: inner.state },
                data: { ...data, permitsRequired: inner.data },
            };
        }
        case "PERMITS_REQUIRED_NOTE": {
            const inner = Fields.permitsRequiredNote.reduce(
                state.permitsRequiredNote,
                data.permitsRequiredNote,
                action.action,
                subcontext
            );
            return {
                state: { ...state, permitsRequiredNote: inner.state },
                data: { ...data, permitsRequiredNote: inner.data },
            };
        }
        case "POTENTIAL_BUDGET_CHALLENGES": {
            const inner = Fields.potentialBudgetChallenges.reduce(
                state.potentialBudgetChallenges,
                data.potentialBudgetChallenges,
                action.action,
                subcontext
            );
            return {
                state: { ...state, potentialBudgetChallenges: inner.state },
                data: { ...data, potentialBudgetChallenges: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    projectedStartDate: function (
        props: WidgetExtraProps<typeof Fields.projectedStartDate> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PROJECTED_START_DATE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "projectedStartDate",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.projectedStartDate.component
                state={context.state.projectedStartDate}
                data={context.data.projectedStartDate}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Projected Start Date"}
            />
        );
    },
    anticipatedDuration: function (
        props: WidgetExtraProps<typeof Fields.anticipatedDuration> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "ANTICIPATED_DURATION",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "anticipatedDuration",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.anticipatedDuration.component
                state={context.state.anticipatedDuration}
                data={context.data.anticipatedDuration}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Anticipated Duration"}
            />
        );
    },
    availableWorkingDays: function (
        props: WidgetExtraProps<typeof Fields.availableWorkingDays> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "AVAILABLE_WORKING_DAYS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "availableWorkingDays",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.availableWorkingDays.component
                state={context.state.availableWorkingDays}
                data={context.data.availableWorkingDays}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Available Working Days"}
            />
        );
    },
    anticipatedCrewSize: function (
        props: WidgetExtraProps<typeof Fields.anticipatedCrewSize> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "ANTICIPATED_CREW_SIZE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "anticipatedCrewSize",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.anticipatedCrewSize.component
                state={context.state.anticipatedCrewSize}
                data={context.data.anticipatedCrewSize}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Anticipated Crew Size"}
            />
        );
    },
    mockupExpectations: function (
        props: WidgetExtraProps<typeof Fields.mockupExpectations> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "MOCKUP_EXPECTATIONS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "mockupExpectations",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.mockupExpectations.component
                state={context.state.mockupExpectations}
                data={context.data.mockupExpectations}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Mockup Expectations"}
            />
        );
    },
    mockupExpectationsNote: function (
        props: WidgetExtraProps<typeof Fields.mockupExpectationsNote> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "MOCKUP_EXPECTATIONS_NOTE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "mockupExpectationsNote",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.mockupExpectationsNote.component
                state={context.state.mockupExpectationsNote}
                data={context.data.mockupExpectationsNote}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Mockup Expectations Note"}
            />
        );
    },
    mockupExpectationsDue: function (
        props: WidgetExtraProps<typeof Fields.mockupExpectationsDue> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "MOCKUP_EXPECTATIONS_DUE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "mockupExpectationsDue",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.mockupExpectationsDue.component
                state={context.state.mockupExpectationsDue}
                data={context.data.mockupExpectationsDue}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Mockup Expectations Due"}
            />
        );
    },
    projectPhases: function (
        props: WidgetExtraProps<typeof Fields.projectPhases> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PROJECT_PHASES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "projectPhases", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.projectPhases.component
                state={context.state.projectPhases}
                data={context.data.projectPhases}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Project Phases"}
            />
        );
    },
    preexistingConditions: function (
        props: WidgetExtraProps<typeof Fields.preexistingConditions> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PREEXISTING_CONDITIONS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "preexistingConditions",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.preexistingConditions.component
                state={context.state.preexistingConditions}
                data={context.data.preexistingConditions}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Preexisting Conditions"}
            />
        );
    },
    potentialForUnforeseenWork: function (
        props: WidgetExtraProps<typeof Fields.potentialForUnforeseenWork> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "POTENTIAL_FOR_UNFORESEEN_WORK",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "potentialForUnforeseenWork",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.potentialForUnforeseenWork.component
                state={context.state.potentialForUnforeseenWork}
                data={context.data.potentialForUnforeseenWork}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Potential for Unforeseen Work"}
            />
        );
    },
    clientSuccessCommunications: function (
        props: WidgetExtraProps<typeof Fields.clientSuccessCommunications> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "CLIENT_SUCCESS_COMMUNICATIONS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "clientSuccessCommunications",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.clientSuccessCommunications.component
                state={context.state.clientSuccessCommunications}
                data={context.data.clientSuccessCommunications}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Client Success Communications"}
            />
        );
    },
    writtenOrVerbalPromises: function (
        props: WidgetExtraProps<typeof Fields.writtenOrVerbalPromises> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "WRITTEN_OR_VERBAL_PROMISES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "writtenOrVerbalPromises",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.writtenOrVerbalPromises.component
                state={context.state.writtenOrVerbalPromises}
                data={context.data.writtenOrVerbalPromises}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Written or Verbal Promises"}
            />
        );
    },
    requiredEquipmentList: function (
        props: WidgetExtraProps<typeof Fields.requiredEquipmentList> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "REQUIRED_EQUIPMENT_LIST",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "requiredEquipmentList",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.requiredEquipmentList.component
                state={context.state.requiredEquipmentList}
                data={context.data.requiredEquipmentList}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Required Equipment List"}
            />
        );
    },
    challengingLocations: function (
        props: WidgetExtraProps<typeof Fields.challengingLocations> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "CHALLENGING_LOCATIONS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "challengingLocations",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.challengingLocations.component
                state={context.state.challengingLocations}
                data={context.data.challengingLocations}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Challenging Locations"}
            />
        );
    },
    requiredNotices: function (
        props: WidgetExtraProps<typeof Fields.requiredNotices> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "REQUIRED_NOTICES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "requiredNotices", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.requiredNotices.component
                state={context.state.requiredNotices}
                data={context.data.requiredNotices}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Required Notices"}
            />
        );
    },
    siteStorage: function (
        props: WidgetExtraProps<typeof Fields.siteStorage> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "SITE_STORAGE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "siteStorage", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.siteStorage.component
                state={context.state.siteStorage}
                data={context.data.siteStorage}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Site Storage"}
            />
        );
    },
    liftParkingRequired: function (
        props: WidgetExtraProps<typeof Fields.liftParkingRequired> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "LIFT_PARKING_REQUIRED",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "liftParkingRequired",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.liftParkingRequired.component
                state={context.state.liftParkingRequired}
                data={context.data.liftParkingRequired}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Lift Parking Required"}
            />
        );
    },
    liftOvernightParkingLocation: function (
        props: WidgetExtraProps<typeof Fields.liftOvernightParkingLocation> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "LIFT_OVERNIGHT_PARKING_LOCATION",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "liftOvernightParkingLocation",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.liftOvernightParkingLocation.component
                state={context.state.liftOvernightParkingLocation}
                data={context.data.liftOvernightParkingLocation}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Lift Overnight Parking Location"}
            />
        );
    },
    portableToiletRequired: function (
        props: WidgetExtraProps<typeof Fields.portableToiletRequired> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PORTABLE_TOILET_REQUIRED",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "portableToiletRequired",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.portableToiletRequired.component
                state={context.state.portableToiletRequired}
                data={context.data.portableToiletRequired}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Portable Toilet Required"}
            />
        );
    },
    portableToiletProposedLocation: function (
        props: WidgetExtraProps<
            typeof Fields.portableToiletProposedLocation
        > & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PORTABLE_TOILET_PROPOSED_LOCATION",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "portableToiletProposedLocation",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.portableToiletProposedLocation.component
                state={context.state.portableToiletProposedLocation}
                data={context.data.portableToiletProposedLocation}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Portable Toilet Proposed Location"}
            />
        );
    },
    locationOfOnSiteWashrooms: function (
        props: WidgetExtraProps<typeof Fields.locationOfOnSiteWashrooms> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "LOCATION_OF_ON_SITE_WASHROOMS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "locationOfOnSiteWashrooms",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.locationOfOnSiteWashrooms.component
                state={context.state.locationOfOnSiteWashrooms}
                data={context.data.locationOfOnSiteWashrooms}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Location of on Site Washrooms"}
            />
        );
    },
    parking: function (
        props: WidgetExtraProps<typeof Fields.parking> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PARKING",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "parking", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.parking.component
                state={context.state.parking}
                data={context.data.parking}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Parking"}
            />
        );
    },
    parkingNotes: function (
        props: WidgetExtraProps<typeof Fields.parkingNotes> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PARKING_NOTES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "parkingNotes", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.parkingNotes.component
                state={context.state.parkingNotes}
                data={context.data.parkingNotes}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Parking Notes"}
            />
        );
    },
    potentialDelays: function (
        props: WidgetExtraProps<typeof Fields.potentialDelays> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "POTENTIAL_DELAYS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "potentialDelays", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.potentialDelays.component
                state={context.state.potentialDelays}
                data={context.data.potentialDelays}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Potential Delays"}
            />
        );
    },
    colourApprovalProcess: function (
        props: WidgetExtraProps<typeof Fields.colourApprovalProcess> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "COLOUR_APPROVAL_PROCESS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "colourApprovalProcess",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.colourApprovalProcess.component
                state={context.state.colourApprovalProcess}
                data={context.data.colourApprovalProcess}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Colour Approval Process"}
            />
        );
    },
    permitsRequired: function (
        props: WidgetExtraProps<typeof Fields.permitsRequired> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PERMITS_REQUIRED",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "permitsRequired", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.permitsRequired.component
                state={context.state.permitsRequired}
                data={context.data.permitsRequired}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Permits Required"}
            />
        );
    },
    permitsRequiredNote: function (
        props: WidgetExtraProps<typeof Fields.permitsRequiredNote> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PERMITS_REQUIRED_NOTE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "permitsRequiredNote",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.permitsRequiredNote.component
                state={context.state.permitsRequiredNote}
                data={context.data.permitsRequiredNote}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Permits Required Note"}
            />
        );
    },
    potentialBudgetChallenges: function (
        props: WidgetExtraProps<typeof Fields.potentialBudgetChallenges> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "POTENTIAL_BUDGET_CHALLENGES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "potentialBudgetChallenges",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.potentialBudgetChallenges.component
                state={context.state.potentialBudgetChallenges}
                data={context.data.potentialBudgetChallenges}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Potential Budget Challenges"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: DETAIL_SHEET_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let projectedStartDateState;
        {
            const inner = Fields.projectedStartDate.initialize(
                data.projectedStartDate,
                subcontext,
                subparameters.projectedStartDate
            );
            projectedStartDateState = inner.state;
            data = { ...data, projectedStartDate: inner.data };
        }
        let anticipatedDurationState;
        {
            const inner = Fields.anticipatedDuration.initialize(
                data.anticipatedDuration,
                subcontext,
                subparameters.anticipatedDuration
            );
            anticipatedDurationState = inner.state;
            data = { ...data, anticipatedDuration: inner.data };
        }
        let availableWorkingDaysState;
        {
            const inner = Fields.availableWorkingDays.initialize(
                data.availableWorkingDays,
                subcontext,
                subparameters.availableWorkingDays
            );
            availableWorkingDaysState = inner.state;
            data = { ...data, availableWorkingDays: inner.data };
        }
        let anticipatedCrewSizeState;
        {
            const inner = Fields.anticipatedCrewSize.initialize(
                data.anticipatedCrewSize,
                subcontext,
                subparameters.anticipatedCrewSize
            );
            anticipatedCrewSizeState = inner.state;
            data = { ...data, anticipatedCrewSize: inner.data };
        }
        let mockupExpectationsState;
        {
            const inner = Fields.mockupExpectations.initialize(
                data.mockupExpectations,
                subcontext,
                subparameters.mockupExpectations
            );
            mockupExpectationsState = inner.state;
            data = { ...data, mockupExpectations: inner.data };
        }
        let mockupExpectationsNoteState;
        {
            const inner = Fields.mockupExpectationsNote.initialize(
                data.mockupExpectationsNote,
                subcontext,
                subparameters.mockupExpectationsNote
            );
            mockupExpectationsNoteState = inner.state;
            data = { ...data, mockupExpectationsNote: inner.data };
        }
        let mockupExpectationsDueState;
        {
            const inner = Fields.mockupExpectationsDue.initialize(
                data.mockupExpectationsDue,
                subcontext,
                subparameters.mockupExpectationsDue
            );
            mockupExpectationsDueState = inner.state;
            data = { ...data, mockupExpectationsDue: inner.data };
        }
        let projectPhasesState;
        {
            const inner = Fields.projectPhases.initialize(
                data.projectPhases,
                subcontext,
                subparameters.projectPhases
            );
            projectPhasesState = inner.state;
            data = { ...data, projectPhases: inner.data };
        }
        let preexistingConditionsState;
        {
            const inner = Fields.preexistingConditions.initialize(
                data.preexistingConditions,
                subcontext,
                subparameters.preexistingConditions
            );
            preexistingConditionsState = inner.state;
            data = { ...data, preexistingConditions: inner.data };
        }
        let potentialForUnforeseenWorkState;
        {
            const inner = Fields.potentialForUnforeseenWork.initialize(
                data.potentialForUnforeseenWork,
                subcontext,
                subparameters.potentialForUnforeseenWork
            );
            potentialForUnforeseenWorkState = inner.state;
            data = { ...data, potentialForUnforeseenWork: inner.data };
        }
        let clientSuccessCommunicationsState;
        {
            const inner = Fields.clientSuccessCommunications.initialize(
                data.clientSuccessCommunications,
                subcontext,
                subparameters.clientSuccessCommunications
            );
            clientSuccessCommunicationsState = inner.state;
            data = { ...data, clientSuccessCommunications: inner.data };
        }
        let writtenOrVerbalPromisesState;
        {
            const inner = Fields.writtenOrVerbalPromises.initialize(
                data.writtenOrVerbalPromises,
                subcontext,
                subparameters.writtenOrVerbalPromises
            );
            writtenOrVerbalPromisesState = inner.state;
            data = { ...data, writtenOrVerbalPromises: inner.data };
        }
        let requiredEquipmentListState;
        {
            const inner = Fields.requiredEquipmentList.initialize(
                data.requiredEquipmentList,
                subcontext,
                subparameters.requiredEquipmentList
            );
            requiredEquipmentListState = inner.state;
            data = { ...data, requiredEquipmentList: inner.data };
        }
        let challengingLocationsState;
        {
            const inner = Fields.challengingLocations.initialize(
                data.challengingLocations,
                subcontext,
                subparameters.challengingLocations
            );
            challengingLocationsState = inner.state;
            data = { ...data, challengingLocations: inner.data };
        }
        let requiredNoticesState;
        {
            const inner = Fields.requiredNotices.initialize(
                data.requiredNotices,
                subcontext,
                subparameters.requiredNotices
            );
            requiredNoticesState = inner.state;
            data = { ...data, requiredNotices: inner.data };
        }
        let siteStorageState;
        {
            const inner = Fields.siteStorage.initialize(
                data.siteStorage,
                subcontext,
                subparameters.siteStorage
            );
            siteStorageState = inner.state;
            data = { ...data, siteStorage: inner.data };
        }
        let liftParkingRequiredState;
        {
            const inner = Fields.liftParkingRequired.initialize(
                data.liftParkingRequired,
                subcontext,
                subparameters.liftParkingRequired
            );
            liftParkingRequiredState = inner.state;
            data = { ...data, liftParkingRequired: inner.data };
        }
        let liftOvernightParkingLocationState;
        {
            const inner = Fields.liftOvernightParkingLocation.initialize(
                data.liftOvernightParkingLocation,
                subcontext,
                subparameters.liftOvernightParkingLocation
            );
            liftOvernightParkingLocationState = inner.state;
            data = { ...data, liftOvernightParkingLocation: inner.data };
        }
        let portableToiletRequiredState;
        {
            const inner = Fields.portableToiletRequired.initialize(
                data.portableToiletRequired,
                subcontext,
                subparameters.portableToiletRequired
            );
            portableToiletRequiredState = inner.state;
            data = { ...data, portableToiletRequired: inner.data };
        }
        let portableToiletProposedLocationState;
        {
            const inner = Fields.portableToiletProposedLocation.initialize(
                data.portableToiletProposedLocation,
                subcontext,
                subparameters.portableToiletProposedLocation
            );
            portableToiletProposedLocationState = inner.state;
            data = { ...data, portableToiletProposedLocation: inner.data };
        }
        let locationOfOnSiteWashroomsState;
        {
            const inner = Fields.locationOfOnSiteWashrooms.initialize(
                data.locationOfOnSiteWashrooms,
                subcontext,
                subparameters.locationOfOnSiteWashrooms
            );
            locationOfOnSiteWashroomsState = inner.state;
            data = { ...data, locationOfOnSiteWashrooms: inner.data };
        }
        let parkingState;
        {
            const inner = Fields.parking.initialize(
                data.parking,
                subcontext,
                subparameters.parking
            );
            parkingState = inner.state;
            data = { ...data, parking: inner.data };
        }
        let parkingNotesState;
        {
            const inner = Fields.parkingNotes.initialize(
                data.parkingNotes,
                subcontext,
                subparameters.parkingNotes
            );
            parkingNotesState = inner.state;
            data = { ...data, parkingNotes: inner.data };
        }
        let potentialDelaysState;
        {
            const inner = Fields.potentialDelays.initialize(
                data.potentialDelays,
                subcontext,
                subparameters.potentialDelays
            );
            potentialDelaysState = inner.state;
            data = { ...data, potentialDelays: inner.data };
        }
        let colourApprovalProcessState;
        {
            const inner = Fields.colourApprovalProcess.initialize(
                data.colourApprovalProcess,
                subcontext,
                subparameters.colourApprovalProcess
            );
            colourApprovalProcessState = inner.state;
            data = { ...data, colourApprovalProcess: inner.data };
        }
        let permitsRequiredState;
        {
            const inner = Fields.permitsRequired.initialize(
                data.permitsRequired,
                subcontext,
                subparameters.permitsRequired
            );
            permitsRequiredState = inner.state;
            data = { ...data, permitsRequired: inner.data };
        }
        let permitsRequiredNoteState;
        {
            const inner = Fields.permitsRequiredNote.initialize(
                data.permitsRequiredNote,
                subcontext,
                subparameters.permitsRequiredNote
            );
            permitsRequiredNoteState = inner.state;
            data = { ...data, permitsRequiredNote: inner.data };
        }
        let potentialBudgetChallengesState;
        {
            const inner = Fields.potentialBudgetChallenges.initialize(
                data.potentialBudgetChallenges,
                subcontext,
                subparameters.potentialBudgetChallenges
            );
            potentialBudgetChallengesState = inner.state;
            data = { ...data, potentialBudgetChallenges: inner.data };
        }
        let state = {
            initialParameters: parameters,
            projectedStartDate: projectedStartDateState,
            anticipatedDuration: anticipatedDurationState,
            availableWorkingDays: availableWorkingDaysState,
            anticipatedCrewSize: anticipatedCrewSizeState,
            mockupExpectations: mockupExpectationsState,
            mockupExpectationsNote: mockupExpectationsNoteState,
            mockupExpectationsDue: mockupExpectationsDueState,
            projectPhases: projectPhasesState,
            preexistingConditions: preexistingConditionsState,
            potentialForUnforeseenWork: potentialForUnforeseenWorkState,
            clientSuccessCommunications: clientSuccessCommunicationsState,
            writtenOrVerbalPromises: writtenOrVerbalPromisesState,
            requiredEquipmentList: requiredEquipmentListState,
            challengingLocations: challengingLocationsState,
            requiredNotices: requiredNoticesState,
            siteStorage: siteStorageState,
            liftParkingRequired: liftParkingRequiredState,
            liftOvernightParkingLocation: liftOvernightParkingLocationState,
            portableToiletRequired: portableToiletRequiredState,
            portableToiletProposedLocation: portableToiletProposedLocationState,
            locationOfOnSiteWashrooms: locationOfOnSiteWashroomsState,
            parking: parkingState,
            parkingNotes: parkingNotesState,
            potentialDelays: potentialDelaysState,
            colourApprovalProcess: colourApprovalProcessState,
            permitsRequired: permitsRequiredState,
            permitsRequiredNote: permitsRequiredNoteState,
            potentialBudgetChallenges: potentialBudgetChallengesState,
        };
        return {
            state,
            data,
        };
    },
    validate: validate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={DETAIL_SHEET_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    projectedStartDate: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.projectedStartDate>
    >;
    anticipatedDuration: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.anticipatedDuration>
    >;
    availableWorkingDays: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.availableWorkingDays>
    >;
    anticipatedCrewSize: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.anticipatedCrewSize>
    >;
    mockupExpectations: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.mockupExpectations>
    >;
    mockupExpectationsNote: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.mockupExpectationsNote>
    >;
    mockupExpectationsDue: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.mockupExpectationsDue>
    >;
    projectPhases: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.projectPhases>
    >;
    preexistingConditions: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.preexistingConditions>
    >;
    potentialForUnforeseenWork: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.potentialForUnforeseenWork>
    >;
    clientSuccessCommunications: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.clientSuccessCommunications>
    >;
    writtenOrVerbalPromises: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.writtenOrVerbalPromises>
    >;
    requiredEquipmentList: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.requiredEquipmentList>
    >;
    challengingLocations: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.challengingLocations>
    >;
    requiredNotices: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.requiredNotices>
    >;
    siteStorage: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.siteStorage>
    >;
    liftParkingRequired: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.liftParkingRequired>
    >;
    liftOvernightParkingLocation: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.liftOvernightParkingLocation>
    >;
    portableToiletRequired: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.portableToiletRequired>
    >;
    portableToiletProposedLocation: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.portableToiletProposedLocation>
    >;
    locationOfOnSiteWashrooms: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.locationOfOnSiteWashrooms>
    >;
    parking: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.parking>
    >;
    parkingNotes: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.parkingNotes>
    >;
    potentialDelays: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.potentialDelays>
    >;
    colourApprovalProcess: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.colourApprovalProcess>
    >;
    permitsRequired: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.permitsRequired>
    >;
    permitsRequiredNote: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.permitsRequiredNote>
    >;
    potentialBudgetChallenges: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.potentialBudgetChallenges>
    >;
};
// END MAGIC -- DO NOT EDIT
