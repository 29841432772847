import { memoize } from "lodash";
import { PaginatedWidget } from "../../../clay/paginated-widget";
import { RecordWidget } from "../../../clay/widgets";
import { CONTENT_AREA } from "../../styles";
import SiteVisitReportSectionWidget, {
    Action as SiteVisitReportSectionWidgetAction,
    State as SiteVisitReportSectionWidgetState,
} from "../site-visit-report/SiteVisitReportSectionWidget.widget";
import {
    CertifiedForemanAssessment,
    CERTIFIED_FOREMAN_ASSESSMENT_META,
} from "./table";
import * as React from "react";

function SurveySectionWidget(
    index: number
): RecordWidget<
    SiteVisitReportSectionWidgetState,
    CertifiedForemanAssessment,
    {},
    SiteVisitReportSectionWidgetAction,
    {}
> {
    return {
        dataMeta: CERTIFIED_FOREMAN_ASSESSMENT_META,
        reactContext: undefined as any,
        fieldWidgets: undefined as any,
        initialize(data: CertifiedForemanAssessment, context) {
            const inner = SiteVisitReportSectionWidget.initialize(
                data.sections[index],
                context
            );
            const items = data.sections.slice();
            items[index] = inner.data;
            return {
                data: {
                    ...data,
                    sections: items,
                },
                state: inner.state,
            };
        },
        component(props) {
            return (
                <div {...CONTENT_AREA}>
                    <SiteVisitReportSectionWidget.component
                        {...props}
                        data={props.data.sections[index]}
                        final={index + 1 === props.data.sections.length}
                    />
                </div>
            );
        },
        reduce(state, data, action, context) {
            const inner = SiteVisitReportSectionWidget.reduce(
                state,
                data.sections[index],
                action,
                context
            );
            const items = data.sections.slice();
            items[index] = inner.data;
            return {
                state: inner.state,
                data: {
                    ...data,
                    sections: items,
                },
            };
        },
        validate(data, cache) {
            return SiteVisitReportSectionWidget.validate(
                data.sections[index],
                cache
            );
        },
    };
}

const SectionWidgetFactory = memoize(SurveySectionWidget);

export const CertifiedForemanAssessmentWidget = PaginatedWidget({
    dataMeta: CERTIFIED_FOREMAN_ASSESSMENT_META,
    validate(detailSheet, cache, errors) {
        if (detailSheet.date) {
            return [];
        } else {
            return errors;
        }
    },
    pages(data) {
        const pages = [];

        pages.push(
            ...data.sections.map((section, index) => ({
                id: `section-${index}`,
                title: section.name,
                widget: SectionWidgetFactory(index),
            }))
        );

        return pages;
    },
});
